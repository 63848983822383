<template>
    <loading v-if="submitting">
    </loading>
    <div class="container-fluid scrolling-columns"
         v-else>
        <div class="row">
            <div class="col">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">

                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary"
                                @click="loadTemplates">
                            <i class="bi bi-arrow-repeat">
                            </i>
                            Refresh
                        </button>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary"
                                @click="showAddNewNotificationTemplate">
                            Add...
                        </button>
                    </div>
                </div>

                <table class="table table-hover table-striped table-sm">
                    <thead class="atn-header border-bottom border-dark bg-body-tertiary">
                        <tr>
                            <th>
                                Subject Line
                            </th>
                            <th v-if="!currentTemplate">
                                Code
                            </th>
                        </tr>
                    </thead>   
                    <tbody>
                        <tr v-for="template in allTemplates"
                            @click="showEditNotificationTemplate(template)"
                            class="pe-action"
                            :class="[currentTemplate && currentTemplate == template ? 'table-primary' : '']">
                            <td>
                                {{ template.Subject }}
                            </td>
                            <td v-if="!currentTemplate">
                                {{ template.Code }}
                            </td>
                        </tr>
                    </tbody> 
                </table>


                <label class="form-group h5 mt-2">
                    E-Mail Template Mappings
                </label>
                
                <div>
                    <div class="form-floating mt-2"
                         v-for="mapping in actionMappings"
                         :key="mapping.ActionTypecode">
                        <select class="form-select"
                                v-model="mapping.TemplateCode">
                            <option :value="''">(Disabled)</option>
                            <option v-for="template in allTemplates"
                                    :key="template.Code"
                                    :value="template.Code">
                                {{template.Subject}}
                            </option>
                        </select>
                        <label>{{mapping.Name}}</label>
                    </div>

                    <button @click="saveMappings"
                            class="btn btn-primary">
                        Save Mappings
                    </button>
                </div>                
            </div>
            <div class="col-md-9" v-if="currentTemplate">
                <notification-template-editor  v-on:saved="onSave" 
                                                v-on:cancel="onCancel"
                                                :template="currentTemplate"
                                                :showCode="showCode"
                                                :allCopyTemplates="allTemplates"
                                                v-if="currentTemplate">
                </notification-template-editor> 
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import EmailTemplate from '../models/EmailTemplate'

export default {
    props: ['showCode','show'],

    mixins: [
        Token,
        Common
    ],

    methods: {
        // resetTemplate(){
        //     this.showEdit = false;
        // },
        showAddNewNotificationTemplate(){
            // this.resetTemplate();
            this.currentTemplate = null;
            this.activeIndex = null;
            this.currentTemplate = new EmailTemplate();
            this.currentTemplate.Id = "0";

            this.showEditNotificationTemplate(this.currentTemplate,null);
           
          
        },
        showEditNotificationTemplate(templateSelected){
            this.currentTemplate = null;

            Vue.nextTick(() => {
                this.currentTemplate = templateSelected;
                this.editNotificationTemplate = JSON.parse(JSON.stringify(this.currentTemplate));
                this.editDialogTitle =
                this.currentTemplate.Id == '0'
                    ? 'New Notification Template'
                    : 'Edit Notification Template';
                this.showEdit = true;
                });
            
        },

        onSave() {
            this.currentTemplate = null;
            this.loadTemplates();
        },

        onCancel() {    
            this.currentTemplate = null;
        },

        addTemplate() {
            var template = new EmailTemplate();
            template.Id = "0";
            this.onEditTemplateLaunch(template);
        },

        async loadTemplates() {
            this.submitting = true;
            try{
                let r = await this.tryGet('/api/communications/templates/' + this.showCode);

                this.allTemplates = r.Result;

                r = await this.tryGet(`/api/communications/defaults/${this.showCode}`);

                r.Result.forEach(element => {
                    let mapping = this.actionMappings.find(x => x.ActionTypeCode == element.ActionTypeCode);

                    if(!mapping) {
                        return;
                    }

                    mapping.TemplateCode = element.TemplateCode || "";
                });
            }
            catch(ex){
                console.log(ex)
            }
            this.submitting = false;
        },

        async saveMappings() {
            this.submitting = true;
            try{
                let toSend = this.actionMappings.map(x => {
                    return {
                        ActionTypeCode: x.ActionTypeCode,
                        TemplateCode: x.TemplateCode
                    };
                });

                let uri = `/api/communications/defaults/${this.showCode}`;
            
                var result = await this.tryPost(
                        uri,
                        JSON.stringify(toSend), 
                        'application/json');                
            }
            catch(ex){
                console.log(ex)
            }
            
            this.submitting = false;
        }
     },

    data() {
        return {
            allTemplates: [],
            actionMappings: [
                {
                    ActionTypeCode: "AttendeeSessionCalendarRequest",
                    Name: "When Attendee Requests iCal Invite",
                    TemplateCode: ''
                },
                {
                    ActionTypeCode: "SpeakerIcalInviteRequest",
                    Name: "iCal Invite for Speakers",
                    TemplateCode: ''
                },
                {
                    ActionTypeCode: "ModeratorIcalInviteRequest",
                    Name: "iCal Invite for Moderators",
                    TemplateCode: ''
                },
                {
                    ActionTypeCode: "NewAppointment",
                    Name: "New Appointment for Page",
                    TemplateCode: ''
                },
                {
                    ActionTypeCode: "UpdatedAppointment",
                    Name: "Updated Appointment for Page",
                    TemplateCode: ''
                },
                {
                    ActionTypeCode: "NewAppointmentNoMeeting",
                    Name: "New Appointment for Page (No Meeting)",
                    TemplateCode: ''
                },
                {
                    ActionTypeCode: "LoginReminder",
                    Name: "Login Reminder Email",
                    TemplateCode: ''
                },
                {
                    ActionTypeCode: "UnreadChatMessageTemplate",
                    Name: "Unread Chat Reminder Email",
                    TemplateCode: '',
                }
            ],
            currentTemplate: null,
        }
    },

    created: function() {
        this.loadTemplates();
    }
}
</script>
