<template>
    <div v-if="roomCode"
         class="mt-2">
        <div class="row" v-if="!loadingRecordPermissions">
            <div class="col">
                <div class="form-check">
                    <label 
                        class="form-check-label">
                        <input v-model="recordPermissions.CanRecord"
                            class="form-check-input" 
                            type="checkbox">Can Record
                    </label>
                </div>
            </div>
            <div class="col">
                <div class="form-check">
                    <label 
                        class="form-check-label">
                        <input v-model="recordPermissions.AutoConvertRecordings"
                            class="form-check-input" 
                            type="checkbox">Auto-Convert Recordings to HLS
                    </label>
                </div>
            </div>
            <div class="col">
                <div class="form-check">
                    <label 
                        class="form-check-label">
                        <input v-model="recordPermissions.CanUpload"
                            class="form-check-input" 
                            type="checkbox">Can Upload
                    </label>
                </div>
            </div>
            <div class="col-auto">
                <button type="button"
                        class="btn btn-outline-primary"
                        @click="saveRecordPermissions">
                    Save
                </button>
            </div>
        </div>
        <div class="form-floating mt-2" v-else-if="loadingRecordPermissions">
            <loading></loading>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'

export default {
    props: [
        'roomCode',
        'showCode'
    ],

    mixins: [
        Token,
        Common,
    ],

    data() {
        return {
            recordPermissions: null,
            loadingRecordPermissions: true,
        }
    },

    watch: {
        roomCode() {
            this.build();
        }
    },

    methods: {
        async build() {
            this.loadingRecordPermissions = true;
            let result = await this.tryGet(`/api/recording/permissions/${this.showCode}/${this.roomCode}`);
            this.recordPermissions = result.Result;
            this.loadingRecordPermissions = false;
        },

        async saveRecordPermissions() {
            this.loadingRecordPermissions = true;

            let toSave = JSON.stringify([this.recordPermissions]);

            let result = await this.tryPost(`/api/recording/permissions`, toSave, 'application/json');

            this.loadingRecordPermissions = false;
        },

    },

    mounted() {
        this.build();
    }

}
</script>