<template>
    <div>
        <nav class="navbar">
            <div class="container-fluid">
                <h1 class="navbar-brand">
                    Tutorial Recorder
                </h1>
                <div class="d-flex">
                    <select class="form-select">
                        <option v-for="mic in microphones"
                                :key="mic.deviceId"
                                :value="mic.deviceId">
                            {{ mic.label }}
                        </option>
                    </select>
                    <button class="btn btn-primary"
                            @click="captureScreen">
                        Capture Screen
                    </button>
                    <button class="btn btn-primary"
                            @click="startRecord">
                        Start Record
                    </button>
                </div>
            </div>
        </nav>
        <div class="container-fluid">
            <div class="ratio ratio-16x9 border">
                <video ref="screencapture"
                       autoplay
                       muted>

                </video>

                <video ref="presenter"
                       autoplay
                       muted>
                </video>

                <canvas ref="canvas"
                        width="1920"
                        height="1080">

                </canvas>
                <video ref="preview"
                       autoplay
                       muted
                       controls
                       v-show="recordedBlob">
                </video>
                <div style="position: absolute; left: 0; top: 0; right: 0; bottom: 0; display:flex; align-items: center; justify-content: center; font-size: 100px; text-shadow: 2px 2px 2px white; pointer-events: none;">
                    {{ countdownTimer }}
                </div>
            </div>


            <form class="mt-2">
                <input placeholder="Video Name"
                       class="form-control"
                       v-model="videoName" />

                <textarea placeholder="Video Description"
                          class="form-control mt-2"
                            v-model="videoDescription">

                </textarea>
            </form>
            <button class="btn btn-danger"
                    v-if="recording && !paused"
                    @click="toggleRecordPause">
                Pause
            </button>
            <button class="btn btn-danger"
                    v-if="recording && paused"
                    @click="toggleRecordPause">
                Resume
            </button>
            <button class="btn btn-danger"
                    v-if="recording"
                    @click="stopRecord">
                Stop Record
            </button>
            <button class="btn btn-primary"
                    @click="uploadVideo">
                Upload
            </button>
            <button class="btn btn-primary"
                    @click="downloadVideo">
                Download
            </button>
            <button class="btn btn-primary"
                    @click="previewMode = !previewMode">
                Preview
            </button>

            <div>
                {{ uploadedFilePath }}
            </div>
        </div>      
     
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import Toastify from 'toastify-js'

export default {
    props: ['showCode'],

    mixins: [
        Token,
        Common
    ],

    methods: {
        runAnimationFrame() {
            if(!this.shouldRunAnimationFrame) {
                return;
            }

            let canvas = this.$refs.canvas;

            let ctx = this.$refs.canvas.getContext('2d');
            
            let video = this.$refs.screencapture;

            ctx.fillStyle = '#222';
            ctx.fillRect(0, 0, canvas.width, canvas.height);



            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            requestAnimationFrame(this.runAnimationFrame);
        },

        async build() {
            let canAccess = await navigator.mediaDevices.getUserMedia({
                audio: true,
                video: false
            });

            canAccess.getTracks().forEach(t => t.stop());

            let mics = await navigator.mediaDevices.enumerateDevices();
            console.log(mics);
            mics = mics.filter(x => x.kind == 'audioinput');

            this.microphones = mics;

            this.runAnimationFrame();
        },

        async captureScreen() {
            if(this.screenStream) {
                this.screenStream.getTracks().forEach(t=>t.stop());
                this.screenStream = null;
            }

            let stream = await navigator.mediaDevices.getDisplayMedia();
            this.screenStream = stream;

            this.$refs.screencapture.srcObject = stream;
        },

        toggleRecordPause() {
            if(this.paused) {
                this.recorder.resume();
                this.paused = false;
            } else {
                this.recorder.pause();
                this.paused = true;
            }
        },

        async startRecord() {
            this.$refs.screencapture.srcObject = this.screenStream;

            this.micStream = await navigator.mediaDevices.getUserMedia({
                video: false,
                audio: {
                    deviceId: this.selectedMicId
                }
            });

            this.countdownTimer = 1;

            while(this.countdownTimer > 0) {
                await this.countdown();
                this.countdownTimer = this.countdownTimer - 1;
            }

            this.countdownTimer = null;

            let screenTrack = this.screenStream.getTracks()[0];
            let micTrack = this.micStream.getTracks()[0];

            let tracks = [screenTrack, micTrack];

            let stream = new MediaStream(tracks);

            this.recorder = new MediaRecorder(stream, {
                mimeType: 'video/webm;codecs=vp9',
                videoBitsPerSecond: 2500000,
                audioBitsPerSecond: 128000
            });

            this.recorder.ondataavailable = this.onRecorderDataAvailable;

            this.recorder.start();
            this.recording = true;
        },

        stopRecord() {
            this.recorder.stop();
            this.recorder = null;
            this.recording = false;
            this.paused = false;

            this.micStream.getTracks().forEach(t => t.stop());

            this.micStream = null;
        },

        onRecorderDataAvailable(e) {
            let videoData = [e.data];

            let blob = new Blob(videoData, {
                type: 'video/webm'
            });

            this.recordedBlob = blob;

            let url = URL.createObjectURL(this.recordedBlob);

            this.$refs.preview.srcObject = null;
            this.$refs.preview.src = url;
        },

        downloadVideo() {
            var url = URL.createObjectURL(this.recordedBlob);
            var a = document.createElement('a');

            document.body.appendChild(a);
            a.style = 'display: none';
            a.href = url;
            a.download = `${this.videoName || 'untitled video'}.webm`;
            a.click();

            window.URL.revokeObjectURL(url);                 
        },

        async uploadVideo() {
            let finalName = `${this.videoName.replace(" ", "_")}.webm`;                
            let finalNameDescription = `${this.videoName.replace(" ", "_")}.json`;                

            let metadata = {
                Title: this.videoName,
                Description: this.videoDescription
            };
            
            // TODO: have some entity that tracks these uploads & opens
            // for now we'll use Loom

            let form = new FormData();
            form.append('blob', this.recordedBlob, finalName);

            try{
                let r = await this.tryPost('/api/assets/?contentPath=showvideos&showCode=' + this.showCode, form);

                Toastify({
                    text: "Upload successful.",
                    duration: 2500,
                    gravity: "top",
                    position: "right",
                }).showToast();             
                
                this.uploadedFilePath = r.Result[0];
            }
            catch(ex){
                console.log(ex);
            }            


        },

        onUploadSuccess(f) { 
            let path = f.Result[0];

            this.uploadedFilePath = path;
        },

        countdown() {
            return new Promise(r => {
                setTimeout(r, 1000);
            })
        }
    },

    data(){ 
        return {
            videoName: '',
            videoDescription: '',

            microphones: [],
            selectedMicId: 'default',
            recorder: null,
            micStream: null,
            screenStream: null,           
            recording: false,
            paused: false,

            recordStart: null,
            countdownTimer: null,

            recordedBlob: null,
            uploadedFilePath: null,

            previewMode: false,

            shouldRunAnimationFrame: true,
        }
    },

    beforeDestroy() {
        if(this.screenStream) {
            this.screenStream.getTracks().forEach(t => t.stop());
        }

        if(this.audioStream) {
            this.audioStream.getTracks().forEach(t => t.stop());
        }
        
        this.shouldRunAnimationFrame = false;
    },

    mounted(){
        this.build();
    }
}
</script>