<template>
    <form class="form-horizontal">
        <div class="row form-group">
            <div class="col">
                <div class="list-group-item list-group-item-action"
                     @click="onCancel">
                    <div class="d-flex">
                        <i class="fa pe-1"></i>
                        <h6 class="mb-0">
                            Cancel
                        </h6>
                    </div>         
                </div>
            </div>
            <div class="col">
                <div class="list-group-item list-group-item-action"
                     @click="onDelete"
                     v-if="!isNewCategory">
                    <div class="d-flex">
                        <i class="fa pe-1"></i>
                        <h6 class="mb-0">
                            Delete
                        </h6>
                    </div>         
                </div>
            </div>
            <div class="col">
                <div class="list-group-item list-group-item-action"
                     @click="onSave">
                    <div class="d-flex">
                        <i class="fa pe-1"></i>
                        <h6 class="mb-0">
                            Save
                        </h6>
                    </div>         
                </div>
            </div>
        </div>
         <div class="form-group row">
            <label class="col-sm-2 col-form-label">
                Name
            </label>
            <div class="col-sm-10">
                <input :disabled="submitting"
                        placeholder="Name"
                        class="form-control"
                        v-model='editAttendeeCategory.Name'/>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-2 col-form-label">
                Code
            </label>
            <div class="col-sm-10">
            <input :disabled="submitting || !isNewCategory"
                    placeholder="Code"
                    class="form-control"
                    v-model='editAttendeeCategory.Code' />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label class="form-check-label">
                    <input  :disabled="submitting" 
                            class="form-check-input"
                            type="checkbox"
                            v-model="editAttendeeCategory.IsHidden" />
                    Hidden from Users
                </label>
            </div>
            <div class="col-md-6">
                <label class="form-check-label">
                    <input  :disabled="submitting" 
                            class="form-check-input"
                            type="checkbox"
                            v-model="editAttendeeCategory.CanAttendeeChoose" />
                    Can be Added by Attendee
                </label>
            </div>
            <div class="col-md-12 category-icon-chooser">
                <h4>Category Icon</h4>

                <img v-if="editAttendeeCategory.IconUrl" 
                        @click="onChooseIconUrl" 
                        :src="editAttendeeCategory.IconUrl" 
                        class="img-fluid"
                        style="max-height: 64px">
                <div v-else class="no-image-preview" 
                        @click="onChooseIconUrl">
                    No Image Selected
                </div>
                <file-picker :ref="`pickerIcon`" 
                            @fileSelected="onFileSelected($event)" 
                            :showCode="show.Code"
                            type="image"
                            :imagePath="editAttendeeCategory.IconUrl"/>
                <button type="button" class="btn btn-primary" @click="onChooseIconUrl">
                    Choose File...
                </button>  
                <button type="button" 
                        class="btn btn-primary"
                        @click="editAttendeeCategory.IconUrl = null">
                    Clear
                </button>
                {{editAttendeeCategory.IconUrl}}
            </div>
        </div>

        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
                Oops! It looks like something happened. Please double-check and try again.
                <ul>
                    <li v-for="error in validateErrors">
                        {{ error }}
                    </li>
                </ul>
        </div>
    </form>      
</template>

<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
import CommonTypes from '../common.types'
export default {

    props: ['attendeeCategory','show','isNewCategory'],

    mixins: [
        Token,
        Common,
        CommonTypes
    ],

    data() {
        return {
            attendees:[],
            editShow:null,
            editAttendeeCategory:null,
            editAttendee:null,
            validCode:true,
            existsInAttendee:false,
            
        }
    },

    created: function() {
        this.editShow = this.cloneObject(this.show);
        this.editAttendeeCategory = this.cloneObject(this.attendeeCategory);
    },
    methods:{
        onChooseIconUrl(i){
            console.log(i);
            this.$refs.pickerIcon.openPicker();
        },
        onFileSelected(fileName){
            Vue.set(this.editAttendeeCategory, 'IconUrl', fileName);
        },


        async getAttendeesForShow(){
            try{
                let r = await this.tryGet(`/api/show/${this.show.Code}/attendees`);
                
                this.attendees = r.Result;
                console.log(r);
            }
            catch(ex){
                console.log(ex);
            }
        },
        validateForm: function() {

            this.validateErrors = [];
            this.validCode = true;
            if(this.editAttendeeCategory.Code && this.isNewCategory){
                for(var i = 0; i < this.show.AttendeeCategories.length; i++){
                    if(this.editAttendeeCategory.Code.toUpperCase() == this.show.AttendeeCategories[i].Code.toUpperCase()){
                        this.validCode =false; 
                    }
            }
            }
            if(!this.validCode){
                this.validateErrors.push("Another Attendee Category with the same Code already exists."); 
            }
            if(this.existsInAttendee){
                this.validateErrors.push("You cannot delete an Attendee Category that belongs to an Attendee.");
                this.existsInAttendee = false;
            }
            var str = this.editAttendeeCategory.Name;
            if (this.editAttendeeCategory.Name && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("Attendee Category Name cannot be only spaces.");
            }
             if(!this.editAttendeeCategory.Name) {
                this.validateErrors.push("Attendee Category Name cannot be blank.");
            }
            if(!this.editAttendeeCategory.Code) {
                this.validateErrors.push("Attendee Category Code cannot be blank.");
            }
         
            return this.validateErrors.length === 0;
        },
        async onSave(){
            this.submitting=true;

             if(!this.validateForm()) {
                this.submitting = false;
                return;
            }

            this.editAttendeeCategory.Code =  this.editAttendeeCategory.Code;

            var index = this.show.AttendeeCategories.indexOf(this.attendeeCategory);
           
            if(index===-1){
                this.editShow.AttendeeCategories.push(this.editAttendeeCategory);
            }
            else{
                this.editShow.AttendeeCategories[index] = this.editAttendeeCategory;
            }

            var toSave = JSON.parse(JSON.stringify(this.editShow));
            try{
                let r = await this.tryPost('/api/shows', JSON.stringify(toSave), 'application/json');
                    
                if(r.Errors && r.Errors.length == 0) {                     
                    this.$emit('saved', r.Result);
                }
            }
            catch(ex){
                console.log(ex);
            }
            
            this.submitting = false;

        },
        async onDelete(){

            this.submitting = true;

            await this.getAttendeesForShow();
            
            for(var i = 0; i<this.attendees.length; i++){         
                console.log(this.attendees[i].Categories);
                for(var j = 0; j< this.attendees[i].Categories.length; j++){      
                    var curr = this.attendees[i].Categories[j];
                    if(curr.Code === this.attendeeCategory.Code){ 
                       this.existsInAttendee = true;
                    }
                }
            }

            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }

            //DELETE out of attendee categories
            var index = this.show.AttendeeCategories.indexOf(this.attendeeCategory);
            this.editShow.AttendeeCategories.splice(index,1);

            //DELETE out of session categories
            for(var i = 0; i < this.show.SessionCategories.length; i++){
                for(j=0;j<this.show.SessionCategories[i].VisibleToAttendeeCategories.length;j++){
                    var curr = this.show.SessionCategories[i].VisibleToAttendeeCategories[j];
                      if(curr.Name === this.attendeeCategory.Name && curr.Code === this.attendeeCategory.Code && curr.AlwaysShow === this.attendeeCategory.AlwaysShow && curr.Order === this.attendeeCategory.Order){
                           this.editShow.SessionCategories[i].VisibleToAttendeeCategories.splice(j,1);   
                      }

                }

            }
            //DELETE out of vendor categories
            for(var i = 0; i < this.show.VendorCategories.length; i++){
                for(j=0;j<this.show.VendorCategories[i].VisibleToAttendeeCategories.length;j++){
                    var curr = this.show.VendorCategories[i].VisibleToAttendeeCategories[j];
                      if(curr.Name === this.attendeeCategory.Name && curr.Code === this.attendeeCategory.Code && curr.AlwaysShow === this.attendeeCategory.AlwaysShow && curr.Order === this.attendeeCategory.Order){
                           this.editShow.VendorCategories[i].VisibleToAttendeeCategories.splice(j,1);   
                      }
                }
            }           

            var toSave = JSON.parse(JSON.stringify(this.editShow));
            
            try{
                let r = await this.tryPost('/api/shows', JSON.stringify(toSave), 'application/json');
                    
                this.submitting = false;
                if(r.Errors && r.Errors.length == 0) {                     
                    this.$emit('saved', r.Result);
                }
            }
            catch(ex){
                console.log(ex);
                this.submitting = false;
            }
            
       },
       onCancel(){
            this.submitting = false;
            this.$emit('cancel');
        },
    }
}
</script>