<template>
    <loading v-if="submitting">

    </loading>
    <div v-else class="container-fluid scrolling-columns">
        <div class="row">
            <div class="col"
                 :class="{'col-auto': showSidePane}">
                <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
                    <div class="col">
                        <input v-model="searchItem" 
                               class="form-control me-2"
                               placeholder="🔍 Search Forms"
                               @keyup="filterItems">
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-primary"
                                @click="showAddNew">
                            New...
                        </button>
                    </div>
                </div>
            
                <table class="table table-hover table-striped table-sm">
                    <thead class="atn-header border-bottom border-dark bg-body-tertiary">
                        <tr>
                            <th>
                                Name
                            </th>
                            <template v-if="!showSidePane">
                                <th>
                                    Open
                                </th>
                                <th>
                                    Public
                                </th>
                                <th>
                                    Multi-Submit
                                </th>
                                <th>
                                    Editable
                                </th>
                                <th>
                                    Attendee Category
                                </th>
                                <th>
                                    Page Category
                                </th>
                                <th>
                                    Code
                                </th>
                            </template> 
                            <th>
                                Public Links
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filteredItems"
                            :key="item.Code"
                            @click="showSelectPanel(item)"
                            class="pe-action"
                            :class="[currentItem && currentItem == item ? 'table-primary' : '']">
                            <td>
                                {{ item.Name }}
                            </td>
                            <template v-if="!showSidePane">
                                <td>
                                    {{ item.IsOpenOverride ? 'Yes' : 'No' }}
                                </td>
                                <td>
                                    {{ item.AllowAnonymousResponse ? 'Yes' : 'No' }}
                                </td>
                                <td>
                                    {{ item.AllowMultiSubmit ? 'Yes' : 'No' }}
                                </td>
                                <td>
                                    {{ item.AllowEditResponse ? 'Yes' : 'No' }}
                                </td>
                                <td>
                                    {{ getAttendeeCategoryName(item.AttendeeCategoryCode) }}
                                </td>
                                <td>
                                    {{ getVendorCategoryName(item.VendorCategoryCode) }}
                                </td>
                                <td>
                                    {{ item.Code }}
                                </td>
                            </template>                                
                            <td>
                                <div class="btn-group">
                                    <button type="button"  
                                            @click.stop="copyPollLinkToClipboard(item)"
                                            class="btn btn-success">
                                        <i class="bi bi-share-fill"></i>
                                    </button>
                                    <a :href="pollLink(item)"
                                        @click.stop
                                            class="btn btn-success"
                                            target="_blank">
                                        <i class="bi bi-upload"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col" v-if="showSidePane">
                <div class="row">
                    <div class="col"
                        v-if="showSelect">


                        <poll-view :pollCode="currentItem.Code"
                                   :showCode="showCode"
                                   :show="showDetails"
                                   @editRequest="showEditPanel"
                                   @deleteRequest="deleteItem"
                                   @close="onCancel">

                        </poll-view>

                    </div>
                
                    <div class="col"
                        v-else-if="showEdit">
                        <poll-editor 
                                :poll="currentItem"
                                :show="showDetails"
                                @saved="onSave"
                                @cancel="onCancel">
                        </poll-editor>                    
                    </div>
                </div>
            </div>
        </div>  
     </div>
</template>
<script>
import Toastify from 'toastify-js'

import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import Poll from '../models/Poll'
import loading from './loading.vue'
import { getPollAppUrl } from './environment.hci';


export default {
    components: { loading },
    
    props: ['showDetails', 
            'showCode'],

    mixins: [
        Token,
        Common
    ],

    computed: {
        showSidePane() {
            return this.showEdit || this.showSelect;
        }
    },

    methods: {  
        copyPollLinkToClipboard(poll) {
            navigator.clipboard.writeText(this.pollLink(poll));
            Toastify({
                text: "Copied poll link to clipboard.",
                duration: 2500,
                gravity: "top",
                position: "right",
            }).showToast();
        },

        copySurveyLinkToClipboard(poll) {
            navigator.clipboard.writeText(this.surveyLink(poll));
        },

        pollLink: function(poll) {
            return `${getPollAppUrl()}/#/pp/${this.showCode}/${poll.Code}`;
        },
        surveyLink: function(poll) {
            return `${getPollAppUrl()}/#/survey/${this.showCode}/${poll.Code}`;
        },

        getVendorCategoryName(code) {
            if(!code) {
                return '';
            }

            let category = this.showDetails.VendorCategories.find(x => x.Code == code);

            if(!category) {
                return code;
            }

            return category.Name;
        },

        getAttendeeCategoryName(code) {
            if(!code) {
                return '';
            }

            let category = this.showDetails.AttendeeCategories.find(x => x.Code == code);

            if(!category) {
                return code;
            }

            return category.Name;
        },

        async deleteItem(item){
            if(window.confirm("Are you sure you want to delete? This is not reversable.")){
                try{
                    let r = await this.tryDelete(`/api/poll/${this.showCode}/${item.Code}`);
                    
                    if(r.Errors.length !== 0) {
                        return;
                    }
                    this.reload();
                    this.showSelect = false;    
                }
                catch(ex){
                    console.log(ex);
                }
            }
        },

        showAddNew(){
            this.resetItems();
            this.isNew = true;

            this.currentItem = new Poll();
            this.currentItem.TypeCode = "RegForm";
            this.currentItem.Id = '0';
            this.currentItem.ShowCode = this.showCode;
            this.currentItem.Code = this.createUniqueCode();
            
            this.showEditPanel();
        },
        showEditPanel(){
            this.showSelect = false;
            this.showEdit = true;
        },

        showSelectPanel(request, idx){
            this.currentItem = null;
            
            this.showSelect = false;
            this.showEdit = false;

            Vue.nextTick(() => {
                this.currentItem = request;
                this.showSelect = true;
            });
        },

        resetItems(){
            this.showEdit = false;
            this.showSelect = false;
            this.isNew = false;
        },
        reloadAndClear(){
            this.reload();
            this.resetItems();
        },
        onSave() {
            this.reloadAndClear();
        },

        onCancel() {
            if(this.isNew){
                this.resetItems();
                return;
            }
            this.currentItem = null;
            this.showSelect = false;
            this.showEdit = false;
        },

        async reload() {
            this.submitting = true;
            
            try{
                let r = await this.tryGet(`/api/polls/${this.showCode}?typeCode=RegForm`);
                this.allItems = r.Result;
                this.filteredItems = r.Result;


            }
            catch(ex){
                console.log(ex);
            }

            this.submitting = false;
            
        },
        filterItems(){
            this.filteredItems = [];
            var currentSearch = this.searchItem;

            if(!currentSearch){
                this.filteredItems = this.allItems;
                return;
            }

            var itemsBeingFiltered = this.allItems;

            if(currentSearch){
                for(var j =0; j < itemsBeingFiltered.length; j++){
                    var searchThrough = itemsBeingFiltered[j].Name;

                    if((searchThrough 
                        && 
                        (searchThrough.toLowerCase().indexOf(currentSearch.toLowerCase()) != -1 
                        || searchThrough.toLowerCase().indexOf(currentSearch.replace(/\s/g, '').toLowerCase()) != -1 )
                    )
                    || itemsBeingFiltered[j].Code.toLowerCase().indexOf(currentSearch.toLowerCase()) != -1){
                        
                        this.filteredItems.push(itemsBeingFiltered[j]);
                    }
                }
            }
            else{
                this.filteredItems = itemsBeingFiltered;                
            }

            this.filteredItems = [...new Set(this.filteredItems)];
        },
    },

    data() {
        return {
            showEdit: false,
            showSelect: false,

            allItems: [],
            filteredItems: [],
            currentItem: [],
            pageTitle: null,
            itemType: null,
            searchItem: null,


            isNew: false,
        }
    },

    created: function() {
        this.pageTitle = 'Registration Forms';
        this.itemType = 'Registration Form';

        this.reload();
    },
}
</script>