<template>
    <loading v-if="submitting">
    </loading>

    <div v-else>
        <nav-bar>

        </nav-bar>
        <vue-title :title="'Show List'"></vue-title>
        <div class="container-fluid">
            <div class="row mt-2">
                <div class="col">
                    <input class="form-control"
                           type="search"
                           v-model="searchFilter"
                           placeholder="Search...">
                </div>
                <div class="col-auto">
                    <select class="form-select"
                            v-model="filterMode">
                        <option v-for="o in filterModes"
                                :key="o.Code"
                                :value="o.Code">
                            {{o.Name}}
                        </option>
                    </select>
                </div>
                <div class="col-auto">
                    <router-link :to="{ name: 'ShowWizard'}"
                                 class="btn btn-primary">
                        Create
                    </router-link>                    
                </div>
            </div>
            <div class="row">

                <div v-for="show in filteredShows"
                    v-bind:key="show.Code"
                    class="col-xxl-3 col-xl-4 col-sm-6 mt-3">
                    <router-link :to="{ name: 'ShowEdit', params: { showCode: show.Code }}"
                                    class="text-decoration-none card h-100 justify-content-center">
                        
                        <div class="row g-0">
                            <div class="col-md-2">
                                <div class="d-flex p-2 w-100 h-100 justify-content-center align-items-center">
                                    <img :src="show.ShowHeaderImagePath"
                                        style="object-fit: contain;"
                                        class="img-fluid" />            
                                </div>
                            </div>
                            <div class="col-md-10">
                                <div class="card-body">
                                    <h5 class="card-title">{{show.Name}}</h5>
                                    <h6 class="card-text"
                                        v-if="!show.EternalShow">{{show.StartDateTime | moment('ddd MMM Do YYYY h:mm a') }}</h6>
                                    <h6 class="card-text"
                                        v-else>
                                        Community
                                    </h6>
                                    <h6 class="card-text"><small class="text-muted">{{show.Code}}</small></h6>
                                </div>

                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>

</style>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'

export default {
    mixins: [
        Token,
        Common
    ],

    computed: {
        filteredShows() {

            let result = this.shows;

            if(this.filterMode == 'eternal') {
                result = result.filter(x => x.EternalShow);
            } else if(this.filterMode == 'shows') {
                result = result.filter(x => !x.EternalShow);
            }

            if(this.searchFilter) {
                let searchTest = new RegExp(this.searchFilter, 'i');
                result = result.filter(x => searchTest.test(x.Code) || searchTest.test(x.Name));
            }

            return result;
        }
    },

    data() {
        return {
            shows: [],
            searchFilter: '',
            filterMode: 'shows',
            filterModes: [ 
                {
                    Code: 'all',
                    Name: 'View All'
                },
                {
                    Code: 'eternal',
                    Name: 'Communities'
                },
                {
                    Code: 'shows',
                    Name: 'Shows'
                }
            ]
        }
    },

    methods: {
        async reloadShowList() {
            this.submitting = true;
            try{
                let r = await this.tryGet('/api/shows?mode=list');
                this.shows = r.Result;
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;

            await window.$signalr.initializeAsync(this.getUserDetails().token);
        },
    },

    created: function() {
        this.reloadShowList();
    }
}
</script>

