<template>
    <div class="container-fluid scrolling-columns show-section-editor">
        <div class="row">
            <div class="col-xl-5 col-xxl-3">
                <vue-slicklist class="list-group"
                                v-model="show.Sections" lockAxis="y">
                    <h5 class="modal-header">App Sections            
                        <div>
                            <button type="button" class="btn btn-primary mb-2" @click="genericDoShowSave">Save</button>
                        </div>
                    </h5>

                    <div v-if="errors && errors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
                            Oops! It looks like something happened. Please double-check and try again.
                            <ul>
                                <li v-for="error in errors">
                                    {{ error }}
                                </li>
                            </ul>
                    </div>
                
                    <vue-slickitem class="list-group-item" 
                                    v-for="(section, i) in show.Sections" 
                                    :key="i" 
                                    :index="i"
                                    :useDragHandle="true">
                        <div class="form-group row">
                            <div class="col-auto">
                                <i v-if="section.IconUrl"
                                      class="icomoon bi section-icon"
                                      v-handle>
                                    {{section.IconUrl}}                                  
                                </i>
                                <i v-else class="bi-grip-vertical section-icon" v-handle></i>
                            </div>
                            <div class="col g-0">
                                <input placeholder="Section Name" v-model="section.Title" class="form-control"
                                    @focus="setSelectedSection(section, i)">
                            </div>
                            <div class="col-auto">
                                <button type="button" class="btn btn-danger"
                                        @click="removeSection(section)">
                                        <i class="bi-x"></i>
                                </button>
                            </div>
                        </div>

                    </vue-slickitem>
                    <div class="list-group-item" @click="addSection">
                            Add...
                    </div>
                </vue-slicklist>
                <vue-slicklist class="list-group"
                                :useDragHandle="true" 
                                v-model="show.HeaderSections" lockAxis="y">
                    <h5 class="modal-header">Header Buttons            
                    </h5>

               
                    <vue-slickitem class="list-group-item" 
                                    v-for="(section, i) in show.HeaderSections" 
                                    :key="i" 
                                    :index="i"
                                    :useDragHandle="true">
                        <div class="form-group row">
                            <div class="col-auto">
                                <i v-if="section.IconUrl"
                                      class="icomoon bi section-icon">
                                    {{section.IconUrl}}                                  
                                </i>
                                <i class="bi-grip-vertical section-icon" v-handle></i>
                            </div>
                            <div class="col g-0">
                                <input placeholder="Section Name" v-model="section.Title" class="form-control"
                                    @click.stop
                                    @focus="setSelectedSection(section, i)">
                            </div>
                            <div class="col-auto">
                                <button type="button" class="btn btn-danger"
                                        @click.stop="removeHeader(section)">
                                        <i class="bi-x"></i>
                                </button>
                            </div>
                        </div>

                    </vue-slickitem>
                    <div class="list-group-item" @click="addHeaderSection">
                            Add...
                    </div>
                </vue-slicklist>
            </div>
            <div class="section-editor col-xl-5 col-xxl-9">
                <div v-if="selectedSection">
                    <div class="row">
                        <div class="col">
                            <input placeholder="Section Name" v-model="selectedSection.Title" class="form-control">
                        </div>
                    </div>
                    <div class="form-group row">
                        
                        <div class="form-group col-6">
                            <textarea class="form-control form-control-sm" v-model="selectedSection.Description" placeholder="Add a text description here." rows="3"></textarea>
                            <small>Section Image</small>

                            <img v-if="selectedSection.BackgroundImage" 
                                    @click="onChooseBgImage" 
                                    :src="selectedSection.BackgroundImage" 
                                    class="img-fluid"
                                    style="max-height: 96px">
                            <div v-else class="no-image-preview" 
                                    @click="onChooseBgImage">
                                No Image Selected
                            </div>
                            <file-picker :ref="`pickerBg`" 
                                        @fileSelected="onFileSelected($event)" 
                                        :showCode="show.Code"
                                        type="image"
                                        :imagePath="selectedSection.BackgroundImage"/>
                            <button type="button" class="btn btn-primary" @click="onChooseBgImage">
                                Choose File...
                            </button>  
                            <button type="button" class="btn btn-primary" @click="selectedSection.BackgroundImage = null">
                                Clear
                            </button>
                        </div>


                        <div class="form-group col-6">
                            <div class="form-group row">
                                <div class="col">
                                    <button type="button"
                                            class="btn btn-primary"
                                            data-bs-target="#iconPicker"
                                            style="margin-top: 0.5em;"
                                            data-bs-toggle="modal">
                                        <span v-if="!selectedSection.IconUrl">NO ICON</span>
                                        <span style="font-family: icomoon;" >{{selectedSection.IconUrl}}</span>
                                    </button>
                                    
                                </div>
                                <div class="col-sm-4">
                                    <small>Section Size</small>
                                    <select class="form-select form-select-sm"
                                            v-model="selectedSection.Size">
                                        <option selected :value="null">(Please Select...)</option>
                                        <option v-for="size in sizes"
                                                :key="size.Code"
                                                :value="size.Code">
                                            {{size.Name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-3">
                                    <small>Limit</small>
                                    <input type="number"
                                            class="form-control form-control-sm" 
                                            min="0" 
                                            max="10"
                                            v-model="selectedSection.MaxItemsToDisplay">
                                </div>
                            </div>

                            <div class="form-group row">
                                <div class='col'>
                                    <small>Section Type</small>
                                    <select v-model="selectedSection.SectionTypeCode"
                                            @change="onSectionTypeCodeChange"
                                            class="form-select form-select-sm mt-1">
                                        <option :value="''">
                                            None
                                        </option>
                                        <option v-for="type in sectionTypeCodes"
                                                :key="type.Code"
                                                :value="type.Code">
                                            {{type.Name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col" v-if="selectedSection.SectionTypeCode == 'AppLink'">
                                    <small>App Link Type</small>
                                    <select v-model="selectedSection.AppLinkTypeCode" 
                                            class='form-select form-select-sm mt-1'>
                                        <option selected disabled hidden :value="null">(Please Select...)</option>
                                        <option v-for="ciType in appLinkTypeCodes"
                                                :value="ciType.Code"
                                                :key="ciType.Code">
                                            {{ciType.Name}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group">
                                <textarea v-model="selectedSection.EmbedCode"
                                            class='form-control form-control-sm'
                                            rows="2"
                                            placeholder="Embed Code">

                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-6">
                            <label>Visible To...</label>
                            <div class="d-flex flex-wrap">
                                <small class="badge rounded-pill bg-primary" 
                                        v-for="category in selectedSection.VisibleToAttendeeCategories"
                                        :key="category.Code"
                                        @click="removeAttendeeCategoryFromSection(selectedSection, category)">
                                    {{category.Name}}
                                </small>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Add Categories...
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <template v-for="ciType in show.AttendeeCategories">
                                        <button type="button"
                                                v-if="selectedSection.Categories && !selectedSection.VisibleToAttendeeCategories.find(x => x.Code == ciType.Code)"
                                                @click="addAttendeeCategoryToSection(selectedSection, ciType)">
                                            {{ciType.Name}}
                                        </button>
                                    </template> 
                                </div>
                            </div>
                        </div>

                        <div class="col-6 form-group" v-if="selectedSection.SectionTypeCode == 'Vendors' || selectedSection.SectionTypeCode == 'VendorsFavorites'">
                            <label>Page Categories</label>
                            <div class="d-flex flex-wrap">
                                <span class="badge rounded-pill bg-primary" 
                                        v-for="category in selectedSection.Categories"
                                        :key="category.Code"
                                        @click="removeCategoryFromSection(selectedSection, category)">
                                    {{category.Name}}
                                </span>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <select class="form-select form-select-sm" v-model="candidateCategoryForSection">
                                        <option selected :value="null">(Please Select...)</option>
                                        <option v-for="ciType in show.VendorCategories"
                                                :value="ciType"
                                                :key="ciType.Code">
                                            {{ciType.Name}} ({{ciType.Code}})
                                        </option>
                                    </select>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn btn-primary" @click="addCategoryToSection(selectedSection, candidateCategoryForSection)">
                                        Add
                                    </button>
                                </div>
                            </div>

                            <label>Category Display Modes</label>
                            <div v-for="category in selectedSection.Categories"
                                 :key="category.Code">
                                <div>
                                    {{category.Name}}
                                </div>
                                <div>
                                    <div class="btn-group btn-group-sm">
                                        <button v-for="template in pageTemplateCodes"
                                                :key="template.Code"
                                                @click="setSectionCategoryTemplate(template.Code, category.Code)"
                                                type="button"
                                                class="btn btn-outline-primary"
                                                :class="{'active': (selectedSection.CategoryDisplayMode && selectedSection.CategoryDisplayMode[category.Code] == template.Code) || !selectedSection.CategoryDisplayMode[category.Code] && template.Code == 'Normal'}">
                                            {{template.Name}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6" v-else-if="selectedSection.SectionTypeCode == 'SessionScanner' && selectedSection.Settings">
                            Settings: {{ selectedSection.Settings }}

                            <div>
                                <select v-model="selectedSection.Settings.Mode">
                                    <option value="OneScan">
                                        Check-In Scan Required
                                    </option>
                                    <option value="TwoScan">
                                        Check-In and Check-Out Scan Required
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="col-6" v-else-if="selectedSection.SectionTypeCode == 'CustomScanner' && selectedSection.Settings">
                            Settings: {{ selectedSection.Settings }}
                            <div>
                                <input v-model="selectedSection.Settings.CustomScanName"
                                       placeholder="Scan Type Name">
                            </div>
                            <div>
                                <input v-model="selectedSection.Settings.CustomScanCode"
                                       placeholder="Code for Scans">
                            </div>
                            <div>
                                <input v-model="selectedSection.Settings.ViewLeadsLabel"
                                       placeholder="View Leads Label">
                            </div>
                            <div>
                                <label>
                                    <input v-model="selectedSection.Settings.CaptureNotes"
                                       type="checkbox">
                                    Capture Notes
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input v-model="selectedSection.Settings.ShowCheckButton"
                                       type="checkbox">
                                    Can Check if Scanned
                                </label>
                            </div>
                            <template v-if="selectedSection.SectionTypeCode == 'CustomScanner'">
                                <div class="form-floating mt-2">
                                    <input v-model="selectedSection.Settings.CaptureInfoForCodePrefix"
                                        placeholder="Capture Info for Code Prefix"
                                        class="form-control">
                                    <label>
                                        Capture Info for Code Prefix
                                    </label>
                                </div>
                                <div class="form-floating mt-2">
                                    <select v-model="selectedSection.Settings.AssignCategoryForCodePrefix"
                                            class="form-control form-control-sm">
                                        <option :value="''">
                                            (Do not assign a category)
                                        </option>
                                        <option v-for="item in show.AttendeeCategories"
                                                :key="item.Code"
                                                :value="item.Code">
                                            {{ item.Name }}    
                                        </option>
                                    </select>
                                    <label>
                                        Assign Category for Prefix
                                    </label>
                                </div>
                                <div class="form-floating mt-2">
                                    <input v-model="selectedSection.Settings.TitleFieldName"
                                        placeholder="Title Field Name"
                                        class="form-control">
                                    <label>
                                        Title Field Name
                                    </label>
                                </div>                            
                                <div class="form-floating mt-2">
                                    <input v-model="selectedSection.Settings.SubTitleFieldName"
                                        placeholder="Sub-Title Field Name"
                                        class="form-control">
                                    <label>
                                        Sub-Title Field Name
                                    </label>
                                </div>  
                                <div class="form-floating mt-2">
                                    <select v-model="selectedSection.Settings.EmailTemplateCode"
                                            class="form-select"
                                            placeholder="Email To Send">
                                        <option v-for="template in allTemplates"
                                                :value="template.Code"
                                                :key="template.Code">
                                            {{ template.Subject }}
                                        </option>
                                    </select>
                                    <label>
                                        Email to Send
                                    </label>
                                </div>                          
                            </template>
                        </div>


                        <div class="col-6 form-group" v-if="selectedSection.SectionTypeCode == 'Agenda' || selectedSection.SectionTypeCode == 'AgendaFavorites' || selectedSection.SectionTypeCode == 'SessionScanner'">
                            <label>Session Categories</label>
                            <div class="d-flex flex-wrap">
                                <small class="badge rounded-pill bg-primary" 
                                        v-for="category in selectedSection.Categories"
                                        :key="category.Code"
                                        @click="removeCategoryFromSection(selectedSection, category)">
                                    {{category.Name}}
                                </small>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <select v-model="candidateCategoryForSection" class="form-control form-control-sm">
                                        <option selected :value="null">(Please Select...)</option>
                                        <option v-for="ciType in show.SessionCategories"
                                                :value="ciType"
                                                :key="ciType.Code">
                                            {{ciType.Name}} ({{ciType.Code}})
                                        </option>
                                    </select>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn btn-primary" @click="addCategoryToSection(selectedSection, candidateCategoryForSection)">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <label>Image Aspect Ratio</label>
                            <select v-model="selectedSection.AspectRatio" 
                                    class="form-select form-select-sm">
                                <option v-for="aspect in aspectRatios" 
                                        :key="aspect.Code" 
                                        :value="aspect.Code">
                                    {{aspect.Name}} ({{ aspect.Code }})
                                </option>
                            </select>

                        </div>
                        <div class="col-6"
                             v-if="selectedSection.SectionTypeCode == 'Discussion'">
                            <label>Select Discussion</label>
                            <select v-model="selectedSection.RelatedItemCode" 
                                    class="form-select form-select-sm">
                                <option selected disabled hidden :value="null">(Please Select...)</option>
                                    <option v-for="session in tractusLinkDestinations['CHATS']" :value="session.Code">
                                        {{session.DisplayName}}
                                    </option>
                            </select>

                        </div>
                        <div class="col-6 form-group" v-if="selectedSection.SectionTypeCode == 'Attendees'">
                            <label>Attendee Categories</label>
                            <div class="d-flex flex-wrap">
                                <small class="badge rounded-pill bg-primary" 
                                        v-for="category in selectedSection.Categories"
                                        :key="category.Code"
                                        @click="removeCategoryFromSection(selectedSection, category)">
                                    {{category.Name}}
                                </small>
                            </div>


                            <div class="row">
                                <div class="col">
                                    <select v-model="candidateCategoryForSection" class="form-control form-control-sm">
                                        <option selected :value="null">(Please Select...)</option>
                                        <option v-for="ciType in show.AttendeeCategories"
                                                :value="ciType"
                                                :key="ciType.Code">
                                            {{ciType.Name}} ({{ciType.Code}})
                                        </option>
                                    </select>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn btn-primary" @click="addCategoryToSection(selectedSection, candidateCategoryForSection)">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="col-6 form-group" 
                             v-if="selectedSection.SectionTypeCode == 'Profile' && tractusLinkDestinations">
                            <label>Profile Type</label>

                            <select v-model="selectedSection.RelatedItemTypeCode" class="form-select form-select-sm">
                                <option selected disabled hidden :value="null">(Please Select...)</option>
                                <option v-for="ciType in relatedItemTypeCodes"
                                        :value="ciType.Code"
                                        :key="ciType.Code">
                                    {{ciType.Name}}
                                </option>
                            </select>

                            <select v-model="selectedSection.RelatedItemCode" class="form-select form-select-sm" v-if="selectedSection.RelatedItemTypeCode == 'Vendor'">
                                <option selected disabled hidden :value="null">(Please Select...)</option>
                                    <option v-for="session in tractusLinkDestinations['VENDORS']" :value="session.Code">{{session.DisplayName}}</option>
                            </select>

                            <select v-model="selectedSection.RelatedItemCode" class="form-select form-select-sm" v-if="selectedSection.RelatedItemTypeCode == 'Session'">
                                <option selected disabled hidden :value="null">(Please Select...)</option>
                                    <option v-for="session in tractusLinkDestinations['SESSIONS']" :value="session.Code">{{session.DisplayName}}</option>
                            </select>
                        </div>
                        <loading v-else-if="selectedSection.SectionTypeCode == 'Profile' && !tractusLinkDestinations" />
                        

                        <div class="col-12 form-group" v-if="selectedSection.SectionTypeCode == 'HtmlContent' || selectedSection.SectionTypeCode == 'HomeContent'">
                            <textarea class="form-control bg-dark text-light font-monospace html-editor" v-model="selectedSection.Content"></textarea>
                        </div>

                        <div class="col-6 form-group" v-if="selectedSection.SectionTypeCode == 'TractusLink'">
                            <label>Tractus Link</label>
                            <resource-selector :showDetails="show" 
                                                :value.sync="selectedSection.Content"
                                                :destinations="tractusLinkDestinations"
                                                v-if="tractusLinkDestinations">

                            </resource-selector>
                            <loading v-else />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" 
                id="iconPicker"
                ref="iconPicker">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body file-upload-modal-body">
                        <div class="row" v-if="selectedSection">
                            <div class="col">
                                <div>
                                    <input v-model="iconSearch" type="text">
                                </div>
                                <div class="icon-container">
                                    <span @click="selectedSection.IconUrl = null;"
                                          class="no-icon"
                                          :class="{ 'background-selected': selectedSection.IconUrl == null }">
                                          NO ICON
                                    </span>
                                    <div v-for="icon in filteredIcons"
                                        @click="onIconClick(icon)"
                                        data-bs-dismiss="modal"
                                        class="icon-picker-display"
                                        :class="{ 'background-selected': selectedSection.IconUrl == icon.Value }"
                                        style="font-family: icomoon;">
                                        <a>{{icon.Value}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button"
                                class="btn btn-primary"
                                data-bs-dismiss="modal">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.background-selected{
    background-color: #0d6efd;
    color:white;
}
.icon-container{
    display: flex; 
    flex-wrap: wrap;
}
.no-icon{
    width: 3em; 
    height: 3em;
    cursor: pointer;
    padding: 0.5em;
}
.currently-selected-icon{
    font-size: 1.5em;  
    padding-left:0.5em;
}
.icon-picker-display{
    font-size: 1.5em; 
    width: 2em; 
    height: 2em; 
    padding: 0.5em;
    cursor: pointer; 
}
</style>
<script>

import Token from './authentication/token'
import Common from './common'
import Vue from 'vue';

import ShowTheme from '../models/ShowTheme';

export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common,
    ],   

    computed:{
        filteredIcons(){
            if(!this.iconSearch){
                return this.icons;
            }
            return this.icons.filter(x => x.Name.toLowerCase().includes(this.iconSearch.toLowerCase()));
        }
    },

    watch: {
        showDetails(to, from) {
            this.show = to;
            this.setupShowDisplay();
        },
        show: {
            deep: true,
            handler() {
                this.bus.$emit('Update-Show-Preview');
            }
        },
    },

    methods: {
        onSectionTypeCodeChange() {
            if(this.selectedSection.SectionTypeCode.indexOf("Scanner")
                && !this.selectedSection.Settings) {
                this.selectedSection.Settings = {
                    Mode: 'TwoScan',
                    CustomScanName: '',
                    CustomScanCode: '',
                    ViewLeadsLabel: 'View Scans',
                    CaptureNotes: false,
                    ShowCheckButton: true,
                    CaptureInfoForCodePrefix: '',
                    AssignCategoryForCodePrefix: '',
                    TitleFieldName: 'Title',
                    SubTitleFieldName: 'Company',
                    EmailTemplateCode: null,
                };
            }
        },

        onIconClick(icon){
            Vue.set(this.selectedSection,"IconUrl",icon.Value);
        },
        onChooseBgImage(i){
            this.$refs.pickerBg.openPicker();
        },
        onFileSelected(fileName){
            this.selectedSection.BackgroundImage = fileName;
        },
        addHeaderSection(){
            this.show.HeaderSections.push({
                Code: this.createUniqueCode(),
                Title: '',
                Description: '',
                SectionTypeCode: null,
                AppLinkTypeCode: null,
                WidgetTypeCode: null,
                AspectRatio: null,
                RelatedItemCode: null,
                RelatedItemTypeCode: null,
                Categories: [],
                MaxItemsToDisplay: 0,
                Content: '',
                Size: 'medium',
                BackgroundImage: null,
                EmbedCode: null,
                HeaderContent: null,
                FooterContent: null,
                VisibleToAttendeeCategories: [],
                CategoryDisplayMode: {},
            });
        },
        addSection() {
            this.show.Sections.push({
                Code: this.createUniqueCode(),
                Title: '',
                Description: '',
                SectionTypeCode: null,
                AppLinkTypeCode: null,
                RelatedItemCode: null,
                RelatedItemTypeCode: null,
                WidgetTypeCode: null,
                AspectRatio: null,
                Categories: [],
                MaxItemsToDisplay: 0,
                Content: '',
                Size: 'medium',
                BackgroundImage: null,
                EmbedCode: null,
                HeaderContent: null,
                FooterContent: null,
                VisibleToAttendeeCategories: [],
                CategoryDisplayMode: {},
            });
        },
        removeHeader(section){
            this.show.HeaderSections.splice(this.show.HeaderSections.indexOf(section), 1);
        },
        removeSection(section) {
            this.show.Sections.splice(this.show.Sections.indexOf(section), 1);
        },
        removeAttendeeCategoryFromSection(section, category) {
            section.VisibleToAttendeeCategories.splice(section.VisibleToAttendeeCategories.indexOf(category), 1);
        },
        addAttendeeCategoryToSection(section, category) {
            // TODO: Clear the array if we switch the section type
            
            if(category == null){
                return;
            }
            if(section.VisibleToAttendeeCategories.some(s => s.Code == category.Code)){
                return;
            }
            
            section.VisibleToAttendeeCategories.push(category);
        },
        removeCategoryFromSection(section, category) {
            section.Categories.splice(section.Categories.indexOf(category), 1);
        },
        addCategoryToSection(section, category) {
            // TODO: Clear the array if we switch the section type

            if(category == null){
                return;
            }

            if(section.Categories.some(s => s.Code == category.Code)){
                return;
            }

            section.Categories.push(category);
        },
        validate(){
            this.errors = [];
        },

        async genericDoShowSave() {
            this.validate();
            
            if(this.errors.length !== 0){
                return;
            }

            this.submitting = true;

            var toSave = JSON.parse(JSON.stringify(this.show));

            try{
                await this.tryPost('/api/shows', JSON.stringify(toSave), 'application/json');
                    
                this.bus.$emit('Do-Show-Reload');
            }
            catch(ex){
                console.log(ex);
            }
            
            this.submitting = false;
        },
        setupShowDisplay() {
            if(this.show == null) {
                return;
            }

            if(!this.show.Theme) {
                Vue.set(this.show, "Theme", new ShowTheme());
            }
            
        },
        setSelectedSection(s, i) {
            this.selectedSection = s;

            if(!s) {
                return;
            }

            if(!s.CategoryDisplayMode) {
                Vue.set(s, 'CategoryDisplayMode', {});
            }

            if(!s.AspectRatio) {
                Vue.set(s, 'AspectRatio', '');
            }

            if(!s.WidgetTypeCode) {
                Vue.set(s, 'WidgetTypeCode', '');
            }
        },

        setSectionCategoryTemplate(displayMode, categoryCode) {
            Vue.set(this.selectedSection.CategoryDisplayMode, categoryCode, displayMode);
        },
    },

    data() {
        return {
            show: null,
            selectedSection: null,
            errors: [],

            resources: null,

            sizes: [
                {Name: 'Small', Code: 'small'},
                {Name: 'Small - Home Only', Code: 'small-home'},
                
                {Name: 'Medium', Code: 'medium'},
                {Name: 'Medium - Home Only', Code: 'medium-home'},

                {Name: 'Large', Code: 'large'},
                {Name: 'Large - Home Only', Code: 'large-home'},

                {Name: 'Large-Alt', Code: 'largealt'},
                {Name: 'Large-Alt - Home Only', Code: 'largealt-home'},

                {Name: 'Huge', Code: 'huge'},
                {Name: 'Huge - Home Only', Code: 'huge-home'},

                {Name: 'Huge-Alt', Code:'hugealt'},
                {Name: 'Huge-Alt - Home Only', Code:'hugealt-home'},

                {Name: 'MenuOnly', Code:'menuonly'},
                {Name: 'MenuOnlyImage', Code:'menuonly-image'},

                {Name: 'Small - HomeOnly - Image', Code:'small-home-image'},
                {Name: 'Medium - HomeOnly - Image', Code:'medium-home-image'},
                {Name: 'Large - HomeOnly - Image', Code:'large-home-image'},
                {Name: 'Huge - HomeOnly - Image', Code:'huge-home-image'},
            ],

            pageTemplateCodes: [
                {
                    Name: "Name & Logo",
                    Code: "Normal"
                },
                {
                    Name: "Name, Bio, Logo, and Cover",
                    Code: "Deluxe"
                },
                {
                    Name: "HTML",
                    Code: "CustomHtml"
                }
            ],
            
            sectionTypeCodes: [
                {
                    Name: 'Area',
                    Code: 'AppLink'
                },
                {
                    Name: 'Widget',
                    Code: 'Widget'
                },
                {
                    Name: 'Home Content',
                    Code: 'HomeContent'
                },
                {
                    Name: 'Map',
                    Code: "Map"
                },
                {
                    Name: 'Session Scanner',
                    Code: "SessionScanner"
                },
                {
                    Name: 'Page Scanner',
                    Code: "VendorScanner"
                },
                {
                    Name: 'Custom Scanner',
                    Code: "CustomScanner"
                },
                {
                    Name: 'Pages',
                    Code: 'Vendors'
                },
                {
                    Name: 'Pages - Favorites',
                    Code: 'VendorsFavorites'
                },
                {
                    Name: 'Sessions',
                    Code: 'Agenda'
                },
                {
                    Name: 'Sessions - Favorites',
                    Code: 'AgendaFavorites'
                },
                {
                    Name: 'Page',
                    Code: 'Profile'
                },
                {
                    Name: 'Discussion (Direct)',
                    Code: 'Discussion'
                },
                {
                    Name: 'Website',
                    Code: 'HtmlContent'
                },
                {
                    Name: 'Attendees',
                    Code: 'Attendees'
                },
                {
                    Name: 'TractusLink',
                    Code: 'TractusLink'
                }
            ],

            appLinkTypeCodes: [
                {
                    Name: 'Polls',
                    Code: 'Polls'
                },
                {
                    Name: 'Announcements',
                    Code: 'Announcements'
                },
                {
                    Name: 'Networking',
                    Code: 'Networking'
                },
                {
                    Name: "My Chats and Group Chats",
                    Code: "DiscussionArea"
                },
                {
                    Name: "Chat Rooms",
                    Code: "ChatRooms"
                },
                {   
                    Name: "My Profile",
                    Code: "MyProfile"
                },
                {
                    Name: "My Favorites",
                    Code: "MyFavorites"
                },
                {
                    Name: "My Appointments",
                    Code: 'MyAppointments'
                }
            ],

            relatedItemTypeCodes: [
                {
                    Name: 'Pages',
                    Code: 'Vendor'
                },
                {
                    Name: 'Sessions',
                    Code: 'Session'
                },     
            ],

            aspectRatios: [
                {
                    Name: 'Auto-Size',
                    Code: ''
                },
                {
                    Name: '1:1',
                    Code: '1x1'
                },
                {
                    Name: '2:1',
                    Code: '2x1'
                },
                {
                    Name: '3:1',
                    Code: '3x1'
                },
                {
                    Name: '4:1',
                    Code: '4x1'
                },
                {
                    Name: '5:1',
                    Code: '5x1'
                },
                {
                    Name: '6:1',
                    Code: '6x1'
                },
                {
                    Name: '5:2',
                    Code: '5x2'
                },
                {
                    Name: '21:9',
                    Code: '21x9'
                },
                {
                    Name: '4:3',
                    Code: '4x3'
                },
                {
                    Name: '16:9',
                    Code: '16x9'
                },
            ],

            candidateCategoryForSection: null,
            icons:[
            {Name: 'apple', Value: '\uE918'},
{Name: 'microsoftoutlook', Value: '\uE915'},
{Name: 'google', Value: '\uE916'},
{Name: 'microsoftoffice', Value: '\uE917'},
{Name: 'zoom', Value: '\uE90B'},
{Name: 'discord', Value: '\uE90A'},
{Name: 'drag_indicator', Value: '\uE90C'},
{Name: 'drag_handle', Value: '\uE90D'},
{Name: 'send', Value: '\uE906'},
{Name: 'switch_camera', Value: '\uE904'},
{Name: 'home', Value: '\uE900'},
{Name: 'office', Value: '\uE903'},
{Name: 'newspaper', Value: '\uE909'},
{Name: 'pencil', Value: '\uE905'},
{Name: 'images', Value: '\uE90E'},
{Name: 'camera', Value: '\uE90F'},
{Name: 'headphones', Value: '\uE910'},
{Name: 'music', Value: '\uE911'},
{Name: 'play', Value: '\uE912'},
{Name: 'film', Value: '\uE913'},
{Name: 'video-camera', Value: '\uE914'},
{Name: 'bullhorn', Value: '\uE91A'},
{Name: 'connection', Value: '\uE91B'},
{Name: 'feed', Value: '\uE91D'},
{Name: 'mic', Value: '\uE91E'},
{Name: 'profile', Value: '\uE923'},
{Name: 'file-music', Value: '\uE928'},
{Name: 'file-play', Value: '\uE929'},
{Name: 'folder', Value: '\uE92F'},
{Name: 'folder-open', Value: '\uE930'},
{Name: 'folder-plus', Value: '\uE931'},
{Name: 'folder-minus', Value: '\uE932'},
{Name: 'price-tags', Value: '\uE936'},
{Name: 'barcode', Value: '\uE937'},
{Name: 'qrcode', Value: '\uE938'},
{Name: 'ticket', Value: '\uE939'},
{Name: 'cart', Value: '\uE93A'},
{Name: 'credit-card', Value: '\uE93F'},
{Name: 'phone', Value: '\uE942'},
{Name: 'address-book', Value: '\uE944'},
{Name: 'pushpin', Value: '\uE946'},
{Name: 'location2', Value: '\uE948'},
{Name: 'compass2', Value: '\uE94A'},
{Name: 'map2', Value: '\uE94C'},
{Name: 'clock', Value: '\uE94E'},
{Name: 'alarm', Value: '\uE950'},
{Name: 'calendar', Value: '\uE953'},
{Name: 'printer', Value: '\uE954'},
{Name: 'display', Value: '\uE956'},
{Name: 'drawer', Value: '\uE95C'},
{Name: 'floppy-disk', Value: '\uE962'},
{Name: 'undo', Value: '\uE965'},
{Name: 'undo2', Value: '\uE967'},
{Name: 'bubble', Value: '\uE96B'},
{Name: 'bubbles', Value: '\uE96C'},
{Name: 'bubbles2', Value: '\uE96D'},
{Name: 'bubble2', Value: '\uE96E'},
{Name: 'bubbles3', Value: '\uE96F'},
{Name: 'bubbles4', Value: '\uE970'},
{Name: 'user', Value: '\uE971'},
{Name: 'users', Value: '\uE972'},
{Name: 'user-plus', Value: '\uE973'},
{Name: 'user-minus', Value: '\uE974'},
{Name: 'user-check', Value: '\uE975'},
{Name: 'search', Value: '\uE986'},
{Name: 'enlarge2', Value: '\uE98B'},
{Name: 'shrink2', Value: '\uE98C'},
{Name: 'key', Value: '\uE98D'},
{Name: 'lock', Value: '\uE98F'},
{Name: 'unlocked', Value: '\uE990'},
{Name: 'wrench', Value: '\uE991'},
{Name: 'equalizer', Value: '\uE992'},
{Name: 'cog', Value: '\uE994'},
{Name: 'stats-dots', Value: '\uE99B'},
{Name: 'trophy', Value: '\uE99E'},
{Name: 'gift', Value: '\uE99F'},
{Name: 'glass2', Value: '\uE9A1'},
{Name: 'mug', Value: '\uE9A2'},
{Name: 'spoon-knife', Value: '\uE9A3'},
{Name: 'bin', Value: '\uE9AC'},
{Name: 'switch', Value: '\uE9B6'},
{Name: 'clipboard', Value: '\uE9B8'},
{Name: 'menu', Value: '\uE9BD'},
{Name: 'cloud-download', Value: '\uE9C2'},
{Name: 'cloud-upload', Value: '\uE9C3'},
{Name: 'download3', Value: '\uE9C7'},
{Name: 'sphere', Value: '\uE9C9'},
{Name: 'link', Value: '\uE9CB'},
{Name: 'attachment', Value: '\uE9CD'},
{Name: 'eye', Value: '\uE9CE'},
{Name: 'eye-blocked', Value: '\uE9D1'},
{Name: 'bookmark', Value: '\uE9D2'},
{Name: 'bookmarks', Value: '\uE9D3'},
{Name: 'star-empty', Value: '\uE9D7'},
{Name: 'star-half', Value: '\uE9D8'},
{Name: 'star-full', Value: '\uE9D9'},
{Name: 'heart', Value: '\uE9DA'},
{Name: 'happy', Value: '\uE9DF'},
{Name: 'smile', Value: '\uE9E1'},
{Name: 'sad', Value: '\uE9E5'},
{Name: 'angry', Value: '\uE9ED'},
{Name: 'neutral', Value: '\uE9F7'},
{Name: 'plus', Value: '\uEA0A'},
{Name: 'minus', Value: '\uEA0B'},
{Name: 'blocked', Value: '\uEA0E'},
{Name: 'cross', Value: '\uEA0F'},
{Name: 'checkmark', Value: '\uEA10'},
{Name: 'checkmark2', Value: '\uEA11'},
{Name: 'enter', Value: '\uEA13'},
{Name: 'exit', Value: '\uEA14'},
{Name: 'play3', Value: '\uEA1C'},
{Name: 'pause2', Value: '\uEA1D'},
{Name: 'stop2', Value: '\uEA1E'},
{Name: 'backward2', Value: '\uEA1F'},
{Name: 'forward3', Value: '\uEA20'},
{Name: 'first', Value: '\uEA21'},
{Name: 'last', Value: '\uEA22'},
{Name: 'volume-medium', Value: '\uEA27'},
{Name: 'volume-mute2', Value: '\uEA2A'},
{Name: 'volume-increase', Value: '\uEA2B'},
{Name: 'volume-decrease', Value: '\uEA2C'},
{Name: 'loop', Value: '\uEA2D'},
{Name: 'loop2', Value: '\uEA2E'},
{Name: 'shuffle', Value: '\uEA30'},
{Name: 'arrow-up2', Value: '\uEA3A'},
{Name: 'arrow-down2', Value: '\uEA3E'},
{Name: 'checkbox-checked', Value: '\uEA52'},
{Name: 'checkbox-unchecked', Value: '\uEA53'},
{Name: 'filter', Value: '\uEA5B'},
{Name: 'share', Value: '\uEA7D'},
{Name: 'share2', Value: '\uEA82'},
{Name: 'mail', Value: '\uEA83'},
{Name: 'mail2', Value: '\uEA84'},
{Name: 'mail3', Value: '\uEA85'},
{Name: 'amazon', Value: '\uEA87'},
{Name: 'hangouts', Value: '\uEA8E'},
{Name: 'facebook2', Value: '\uEA91'},
{Name: 'instagram', Value: '\uEA92'},
{Name: 'whatsapp', Value: '\uEA93'},
{Name: 'spotify', Value: '\uEA94'},
{Name: 'telegram', Value: '\uEA95'},
{Name: 'twitter', Value: '\uEA96'},
{Name: 'vine', Value: '\uEA97'},
{Name: 'rss', Value: '\uEA9B'},
{Name: 'youtube', Value: '\uEA9D'},
{Name: 'twitch', Value: '\uEA9F'},
{Name: 'vimeo', Value: '\uEAA0'},
{Name: 'flickr2', Value: '\uEAA4'},
{Name: 'steam2', Value: '\uEAAD'},
{Name: 'github', Value: '\uEAB0'},
{Name: 'wordpress', Value: '\uEAB4'},
{Name: 'appleinc', Value: '\uEABE'},
{Name: 'soundcloud', Value: '\uEAC3'},
{Name: 'skype', Value: '\uEAC5'},
{Name: 'reddit', Value: '\uEAC6'},
{Name: 'linkedin', Value: '\uEAC9'},
{Name: 'stackoverflow', Value: '\uEAD0'},
{Name: 'file-pdf', Value: '\uEADF'},
{Name: 'file-word', Value: '\uEAE1'},
{Name: 'warning', Value: '\uEA07'},
{Name: 'notification', Value: '\uEA08'},
{Name: 'question', Value: '\uEA09'},
{Name: 'info', Value: '\uEA0C'},
{Name: 'cancel-circle', Value: '\uEA0D'},
{Name: 'blocked1', Value: '\uEA12'},
{Name: 'pencil5', Value: '\uE919'},
{Name: 'quill4', Value: '\uE91C'},
{Name: 'clapboard-play', Value: '\uE959'},
{Name: 'recording', Value: '\uE969'},
{Name: 'gamepad', Value: '\uE976'},
{Name: 'gamepad3', Value: '\uE977'},
{Name: 'connection1', Value: '\uE97C'},
{Name: 'connection2', Value: '\uE97D'},
{Name: 'connection3', Value: '\uE97E'},
{Name: 'connection4', Value: '\uE97F'},
{Name: 'antenna', Value: '\uE983'},
{Name: 'satellite-dish2', Value: '\uE985'},
{Name: 'antenna2', Value: '\uE988'},
{Name: 'mic1', Value: '\uE989'},
{Name: 'mic-off', Value: '\uE98A'},
{Name: 'mic2', Value: '\uE98E'},
{Name: 'mic-off2', Value: '\uE993'},
{Name: 'file-download', Value: '\uE9A5'},
{Name: 'file-download2', Value: '\uE9A6'},
{Name: 'folder-download2', Value: '\uE9E3'},
{Name: 'folder-download3', Value: '\uE9E6'},
{Name: 'lifebuoy', Value: '\uEA1B'},
{Name: 'bell2', Value: '\uEA58'},
{Name: 'bell3', Value: '\uEA59'},
{Name: 'calendar1', Value: '\uEA5F'},
{Name: 'download', Value: '\uEA88'},
{Name: 'floppy-disk2', Value: '\uEA8C'},
{Name: 'reply', Value: '\uEAC2'},
{Name: 'reply-all', Value: '\uEAC4'},
{Name: 'lock4', Value: '\uEB53'},
{Name: 'unlocked1', Value: '\uEB54'},
{Name: 'hammer-wrench', Value: '\uEB69'},
{Name: 'pulse', Value: '\uEB6F'},
{Name: 'ambulance', Value: '\uEB74'},
{Name: 'alarm2', Value: '\uEB75'},
{Name: 'bug2', Value: '\uEB79'},
{Name: 'stats-bars2', Value: '\uEB8A'},
{Name: 'stats-bars3', Value: '\uEB8B'},
{Name: 'rating', Value: '\uEB99'},
{Name: 'rating2', Value: '\uEB9A'},
{Name: 'rating3', Value: '\uEB9B'},
{Name: 'stars', Value: '\uEB9E'},
{Name: 'medal-star', Value: '\uEB9F'},
{Name: 'medal', Value: '\uEBA0'},
{Name: 'medal2', Value: '\uEBA1'},
{Name: 'medal-first', Value: '\uEBA2'},
{Name: 'medal-second', Value: '\uEBA3'},
{Name: 'medal-third', Value: '\uEBA4'},
{Name: 'crown', Value: '\uEBA5'},
{Name: 'trophy1', Value: '\uEBA6'},
{Name: 'trophy2', Value: '\uEBA7'},
{Name: 'coffee', Value: '\uEBCB'},
{Name: 'lamp7', Value: '\uEBF9'},
{Name: 'airplane', Value: '\uEC05'},
{Name: 'paperplane', Value: '\uEC09'},
{Name: 'car', Value: '\uEC0A'},
{Name: 'car2', Value: '\uEC0C'},
{Name: 'gas', Value: '\uEC0D'},
{Name: 'train2', Value: '\uEC13'},
{Name: 'ship', Value: '\uEC14'},
{Name: 'puzzle', Value: '\uEC1F'},
{Name: 'shield2', Value: '\uEC34'},
{Name: 'switch1', Value: '\uEC4B'},
{Name: 'more2', Value: '\uEC5C'},
{Name: 'grid2', Value: '\uEC5E'},
{Name: 'grid3', Value: '\uEC5F'},
{Name: 'grid6', Value: '\uEC62'},
{Name: 'menu1', Value: '\uEC6A'},
{Name: 'download4', Value: '\uEC83'},
{Name: 'download5', Value: '\uEC85'},
{Name: 'bookmark2', Value: '\uECB7'},
{Name: 'bookmark3', Value: '\uECB8'},
{Name: 'heart3', Value: '\uECE9'},
{Name: 'heart4', Value: '\uECEA'},
{Name: 'thumbs-up2', Value: '\uECF5'},
{Name: 'thumbs-down2', Value: '\uECF7'},
{Name: 'thumbs-up3', Value: '\uECF8'},
{Name: 'thumbs-down3', Value: '\uECF9'},
{Name: 'hand', Value: '\uED39'},
{Name: 'dots', Value: '\uED4E'},
{Name: 'radio-checked', Value: '\uEE71'},
{Name: 'radio-checked2', Value: '\uEE72'},
{Name: 'radio-unchecked', Value: '\uEE73'},
{Name: 'toggle-on', Value: '\uEE74'},
{Name: 'toggle-off', Value: '\uEE75'},
{Name: 'checkmark-circle', Value: '\uEE76'},
{Name: 'circle', Value: '\uEE77'},
{Name: 'circle2', Value: '\uEE78'},
{Name: 'circles', Value: '\uEE79'},
{Name: 'circles2', Value: '\uEE7A'},
{Name: 'google2', Value: '\uEEE8'},
{Name: 'edit1, pencil-square-o1', Value: '\uE907'},
{Name: 'edit, pencil-square-o', Value: '\uE908'},
{Name: 'chevron-right', Value: '\uE901'},
{Name: 'chevron-left', Value: '\uE902'},

            ],
            iconSearch: null,
            allTemplates: [],
        }
    },

    async created() {
        this.show = this.showDetails;

        // this.loadResources();
        await this.getTractusLinkDestinationsAsync(this.show.Code);

        let r = await this.tryGet('/api/communications/templates/' + this.show.Code);

        this.allTemplates = r.Result;


        if(!this.show.Theme) {
            Vue.set(this.show, "Theme", {
                HomeBackgrounds: [],
                StyleSheet: '' 
            });
        }

        if(!this.show.Theme.StyleSheet) {
            Vue.set(this.show.Theme, 'StyleSheet', '');
        }

        if(!this.show.Theme.HomeBackgrounds) {
            Vue.set(this.show.Theme, 'HomeBackgrounds', []);
        }

        if(!this.show.HeaderSections){
            Vue.set(this.show, 'HeaderSections', []);
        }


        if(this.show.Sections) {
            for(var i = 0; i < this.show.Sections.length; i++) {
                let section = this.show.Sections[i];

                if(!section.Size) {
                    Vue.set(section, 'Size', 'medium');
                }

                if(!section.AspectRatio) {
                    Vue.set(section, 'AspectRatio', '');
                }

                if(!section.WidgetTypeCode) {
                    Vue.set(section, 'WidgetTypeCode', '');
                }

                if(!section.BackgroundImage) {
                    Vue.set(section, 'BackgroundImage', '');
                }

                if(!section.EmbedCode) {
                    Vue.set(section, 'EmbedCode', '');
                }

                if(!section.HeaderContent) {
                    Vue.set(section, 'HeaderContent', '');
                }

                if(!section.FooterContent) {
                    Vue.set(section, 'FooterContent', '');
                }

                if(!section.SectionTypeCode) {
                    Vue.set(section, 'SectionTypeCode', '');
                }

                if(!section.AppLinkTypeCode) {
                    Vue.set(section, 'AppLinkTypeCode', '');
                }
            }
        }

        //difference between this and the if statement above is it sets style sheet to null rather then empty
        this.setupShowDisplay();
        this.setSelectedSection(this.show.Sections[1])
    },
    
    // beforeDestroy() {
    //     if(this.modal){
    //         this.modal.dispose();
    //     }
    // },
}
</script>