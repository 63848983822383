<template>
    <div>
        <div class="top-editor bg-body-tertiary">
            <div v-if="selectedThemeElement"
                class="theme-element-editor">
                <chrome-picker v-if="selectedThemeElement.type == 'color'" 
                               :value="selectedThemeElement.value"
                               @input="updateColor(selectedThemeElement, $event)" />
                <select v-else-if="selectedThemeElement.type == 'blend'"
                        class="form-control"
                        v-model="selectedThemeElement.value">
                    <option v-for="opt in multiplyValues"
                            :key="opt"
                            :value='opt'>
                        {{opt}}
                    </option>
                </select>                           
                <div v-else-if="selectedThemeElement.type == 'shadow' || selectedThemeElement.type == 'text-shadow'">
                    <chrome-picker :value="selectedShadowColor"
                               @input="updateShadowColor(selectedThemeElement, $event)" />
                    <input v-model="selectedThemeElement.value"
                           class="form-control">                    
                </div>
                <label>{{selectedThemeElement.name}}</label>
            </div>

            <div class="theme-previewer">
                <span class="text-shadow-preview"
                    :style="`background-color: ${previewBackColor.value}; box-shadow: ${previewBoxShadow.value}; text-shadow: ${previewTextShadow.value}; color: ${previewForeColor.value}`">Lorem ipsum</span>

                <select v-model="previewForeColor">
                    <option :value="initial">Text Color</option>
                    <option v-for="opt in theme.filter(x=>x.type =='color')"
                            :key="opt.name"
                            :value="opt">
                        {{opt.name}}
                    </option>
                </select>

                <select v-model="previewBackColor">
                    <option :value="initial">Background</option>
                    <option v-for="opt in theme.filter(x=>x.type =='color')"
                            :key="opt.name"
                            :value="opt">
                        {{opt.name}}
                    </option>
                </select>

                <select v-model="previewBoxShadow">
                    <option :value="initial">Box Shadow</option>
                    <option v-for="opt in theme.filter(x=>x.type =='shadow')"
                            :key="opt.name"
                            :value="opt">
                        {{opt.name}}
                    </option>
                </select>

                <select v-model="previewTextShadow">
                    <option :value="initial">Text Shadow</option>
                    <option v-for="opt in theme.filter(x=>x.type =='text-shadow')"
                            :key="opt.name"
                            :value="opt">
                        {{opt.name}}
                    </option>
                </select>

            </div>
            <div>
                <img v-if="testImage" @click="onChooseHeaderImage" :src="testImage" class="img-fluid palette-image-preview">
                <div v-else class="no-image-preview" @click="onChooseHeaderImage">
                    No Image Selected
                </div>
                
                <div class="swatch-container">
                    <div v-for="swatches in swatchGroups">
                        <div v-for="swatch in swatches"
                            @click="updateWithVibrantColor(selectedThemeElement, swatch)"
                            :style="`background-color: ${swatch.value}`">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <file-picker ref="picker" 
                            @fileSelected="onFileSelected" 
                            :showCode="show.Code"
                            type="image"
                            :imagePath="testImage"/>
        <div class='theme-property-grid'>
            <div v-for="property in theme" :key="property.name"
                 @dragover="$event.preventDefault()"
                 @drop="endDragSwatch(property, $event)"
                :class="[property == selectedThemeElement ? 'property-selected' : '']"
                class='theme-property border' @click="selectedThemeElement = property">
                <div :style="`background-color: ${property.value}`"
                    v-if="property.type == 'color'"
                    class='preview-box color-swatch'>
                </div>
                
                <div v-else-if="property.type == 'shadow'"
                    class="preview-box color-swatch"
                    :style="{'box-shadow': property.value}">
                </div>
                <div :style="{'text-shadow': property.value}"
                    v-else-if="property.type == 'text-shadow'"
                    class='preview-box text-preview'>
                    {{property.name}}
                </div>
                <div v-else-if="property.type == 'blend'">
                    Blend mode: {{property.value}}
                </div>

                <label>{{property.name}}</label>
            </div>
            
        </div>
        <hr>
        <h6>Session Categories</h6>
        <div class='theme-property-grid'>
            <div v-for="property in agendaCategoryTheme" 
                 :key="property.name"
                 @dragover="$event.preventDefault()"
                 @drop="endDragSwatch(property, $event)"
                :class="[property == selectedThemeElement ? 'property-selected' : '']"
                class='theme-property border' @click="selectedThemeElement = property">
                <div :style="`background-color: ${property.value}`"
                    v-if="property.type == 'color'"
                    class='preview-box color-swatch'>
                </div>
                <label>{{property.displayName}}</label>
            </div>
        </div>
        <hr>
        <h6>Page Categories</h6>
        <div class='theme-property-grid'>
            <div v-for="property in vendorCategoryTheme" 
                 :key="property.name"
                 @dragover="$event.preventDefault()"
                 @drop="endDragSwatch(property, $event)"
                :class="[property == selectedThemeElement ? 'property-selected' : '']"
                class='theme-property border' @click="selectedThemeElement = property">
                <div :style="`background-color: ${property.value}`"
                    v-if="property.type == 'color'"
                    class='preview-box color-swatch'>
                </div>
                <label>{{property.displayName}}</label>
            </div>
        </div>
        <hr>
        <h6>Attendee Categories</h6>
        <div class='theme-property-grid'>
            <div v-for="property in attendeeCategoryTheme" 
                 :key="property.name"
                 @dragover="$event.preventDefault()"
                 @drop="endDragSwatch(property, $event)"
                :class="[property == selectedThemeElement ? 'property-selected' : '']"
                class='theme-property border' @click="selectedThemeElement = property">
                <div :style="`background-color: ${property.value}`"
                    v-if="property.type == 'color'"
                    class='preview-box color-swatch'>
                </div>
                <label>{{property.displayName}}</label>
            </div>
        </div>
    </div>
</template>
<style scoped>
.swatch-container {
    display: flex;
    flex-wrap: wrap;
}

.swatch-container > div {
    outline: 1px solid #eee;
}

.swatch-container > div > div {
    padding: 0.5em;
    margin: 0.1em;
    height: 24px;
    width: 48px;
}


.palette-image-preview {
    max-height: 1in;
}

.top-editor {
    display: flex;
    position: sticky;
    top: 0;
}

.theme-previewer {
    display: flex;
    flex-direction: column;
}

.theme-previewer > * {
    margin: 0.2em;
}

.top-editor > div {
    flex-basis: 1;
}

.text-shadow-preview {
    outline: 1px solid #eee;
    padding: 2em;
    font-size: 1.5em;
}

.property-selected {
    background: #ccccff99;
}

.color-swatch {
    width: 32px;
    height: 32px;
}

.text-preview {
    padding: 0.3em;
    font-size: 2em;
}

.preview-box {
    outline: 1px solid #eee;
}

.theme-property-grid {
    display: flex;
    flex-wrap: wrap;
}

.theme-element-editor {
    
}

.theme-property {
    width: calc(25% - 1em);
    margin: 0.5em;
    display: flex;
    flex-direction: row;
    padding: 0.5em;
    align-items: center;
}

.theme-property > label {
    margin-left: 8px;
}

input.color-picker {
    height: 64px;
}
</style>
<script>
import Vue from 'vue'
import * as Vibrant from 'node-vibrant'
import KolorWheel from 'kolorwheel'
console.log(KolorWheel)

// const cssRegex = /(--[a-zA-Z0-9-]*):\s*(#*[a-zA-Z0-9\ ,.\(\)]*)/gm;
const cssRegex = /(--[a-zA-Z0-9-]*):\s*([^;]*)/gm;


const defaultTheme = [                
                {"name": "text-color", "value": "#000", "type": "color"},
                {"name": "subtle-text-color", "value": "#555", "type": "color"},
                {"name": "light-text-color", "value": "#999", "type": "color"},
                {"name": "inverse-text-color", "value": "#fff", "type": "color"},
                {"name": "home-item-background", "value": "#203864", "type": "color"},
                {"name": "home-item-shadow", "value": "3px 2px 3px 0 rgba(0,0,0,0.15)", "type": "shadow"},
                {"name": "home-item-text", "value": "white", "type": "color"},
                {"name": "text-shadow", "value": "1px 1px 1px black", "type": "text-shadow"},
                {"name": "link-light-color", "value": "#D36582", "type": "color"},
                {"name": "link-dark-color", "value": "#203864", "type": "color"},
                {"name": "primary-color", "value": "#2B59C3", "type": "color"},
                {"name": "primary-color-dark", "value": "#203864", "type": "color"},
                {"name": "dark-border-color", "value": "#333", "type": "color"},
                {"name": "dark-bg-color", "value": "#333", "type": "color"},
                {"name": "med-border-color", "value": "#999", "type": "color"},
                {"name": "med-bg-color", "value": "#ccc", "type": "color"},
                {"name": "light-border-color", "value": "#D8D8D8", "type": "color"},
                {"name": "light-bg-color", "value": "#f7f7f7", "type": "color"},
                {"name": "title-bar-color", "value": "#203864", "type": "color"}, 
                {"name": "title-bar-text", "value": "white", "type": "color"},
                {"name": "menu-bg-color", "value": "white", "type": "color"},
                {"name": "modal-bg-color", "value": "rgba(0,0,0,0.6)", "type": "color"},
                {"name": "alert-light-bg-color", "value": "#DD0000", "type": "color"},
                {"name": "alert-dark-bg-color", "value": "darkred", "type": "color"},
                {"name": "home-background", "value": "white", "type": "color"},
                {"name": "home-item-background-multiply", "value": "multiply", "type": "blend"},
                {"name": "home-background-multiply", "value": "multiply", "type": "blend"},
                {"name": "webinar-bg-color", "value": "#303030", "type": "color"},           
                {"name": "webinar-text-color", "value": "#e3e3e3", "type": "color"},           
                {"name": "webinar-control-bar-color", "value": "#404040", "type": "color"},           
                {"name": "webinar-control-btn-color", "value": "#e3e3e3", "type": "color"},
                {"name": 'full-bright-bg-color', "value": '#ffffff', 'type': 'color'},           
                {"name": 'full-dark-bg-color', "value": '#000000', 'type': 'color'},
                {"name": 'right-pane-shadow', "value": '-4px 2px 8px 0px #e4e4e4', 'type': 'shadow'}
            ];

export default {
    props: ['stylesheet', 'show'],

    watch: {
        theme: {
            deep: true,
            handler() {
                this.updateTheme();
            }
        },
        agendaCategoryTheme: {
            deep: true,
            handler() {
                this.updateTheme();
            }
        },
        vendorCategoryTheme: {
            deep: true,
            handler() {
                this.updateTheme();
            }
        },
        attendeeCategoryTheme: {
            deep:true,
            handler() {
                this.updateTheme();
            }   
        }


    },

    computed: {
        selectedShadowColor() {
            if(!this.selectedThemeElement) {
                return;
            }
            let values = this.selectedThemeElement.value.split(' ');

            return values && values.length > 0
                ? values[values.length - 1]
                : '#000';
        }
    },

    methods: {

        startDrag(swatch, e) {
            e.dataTransfer.setData('text', swatch.value);
        },

        endDragSwatch(element, e) {
            e.preventDefault();

            if(element.type == 'text-shadow'
                || element.type == 'shadow') {


                let values = element.value.split(' ');

                if(values && values.length > 0) {
                    values.splice(values.length - 1, 1);
                    values.push(e.dataTransfer.getData('text'));
                    element.value = values.join(' ');
                }


            } else if (element.type == 'color') {
                element.value = e.dataTransfer.getData('text');
            }

        },

        onChooseHeaderImage(){
            this.$refs.picker.openPicker();
        },

        async onFileSelected(fileName){
            this.testImage = fileName;

            let opts = {
                colorCount: 128,
                quality: 4  
            };
            let palette = await Vibrant.from(this.testImage, opts).getPalette();


            this.swatchGroups = [];

            Object.keys(palette).forEach((k, i) => {

                let groupArray = [];

                let toPush = {
                    name: k,
                    value: palette[k].getHex()
                };

                groupArray.push(toPush);

                let kolors = new KolorWheel(toPush.value);
                let items = kolors.rel(0, 0, 80, 5);


                items.resultList.forEach((shade, i) => {
                    let shadeToPush = {
                        name: `${k}`,
                        value: shade.getHex()
                    };

                    groupArray.push(shadeToPush);
                });

                this.swatchGroups.push(groupArray);
            });
        },

        //used in show.theme.editor
        reset() {
            this.initialized = false;
            this.theme.forEach(element => {
                let defaultStyle = defaultTheme.find(x => x.name == element.name);
                if(!defaultStyle) {
                    return;
                }      

                element.value = defaultStyle.value;
            });

            this.initialized = true;
            this.updateTheme();
        },

        rebuild() {
            this.initialized = false;
            let result = (this.stylesheet || ':root { }').match(cssRegex);
            
            this.agendaCategoryTheme = [];
            this.vendorCategoryTheme = [];
            this.attendeeCategoryTheme = [];

            if(result
                && result.forEach)
            {

                result.forEach(element => {
                    let cssParsed = element.split(':');
                    
                    let propertyName = cssParsed[0].replace('--', '').trim();
                    let propertyValue = cssParsed[1].trim();
                    let matchedThemeValue = this.theme.find(x => x.name == propertyName);

                    if(!matchedThemeValue) {

                        if(propertyName.startsWith("S-")) {
                            // It's a theme color.
                            let agendaTheme = {
                                name: propertyName,
                                value: propertyValue,
                                type: 'color'
                            };

                            this.agendaCategoryTheme.push(agendaTheme);
                        }

                        if(propertyName.startsWith("V-")) {
                            // It's a theme color.
                            let vendorTheme = {
                                name: propertyName,
                                value: propertyValue,
                                type: 'color'
                            };

                            this.vendorCategoryTheme.push(vendorTheme);
                        }

                        if(propertyName.startsWith("A-")){
                            this.attendeeCategoryTheme.push({
                                name: propertyName,
                                value: propertyValue,
                                type: 'color'
                            });
                        }

                        return;
                    }

                    matchedThemeValue.value = propertyValue;
                });
            }

            this.show.SessionCategories.forEach(c => {
                let foundValue = this.agendaCategoryTheme.find(x => x.name == `S-${c.Code}`);

                if(!foundValue) {
                    foundValue = {
                        name: `S-${c.Code}`,
                        value: '#aaa',
                        type: 'color'
                    };

                    this.agendaCategoryTheme.push(foundValue);
                }

                Vue.set(foundValue, 'displayName', c.Name);
            });

            this.show.VendorCategories.forEach(c =>{

                let foundVendorValue = this.vendorCategoryTheme.find(x => x.name == `V-${c.Code}`);

                if(!foundVendorValue) {
                    foundVendorValue = {
                        name: `V-${c.Code}`,
                        value: '#aaa',
                        type: 'color'
                    };

                    this.vendorCategoryTheme.push(foundVendorValue);
                }

                Vue.set(foundVendorValue, 'displayName', c.Name);

            });

            this.show.AttendeeCategories.forEach(c =>{

                let foundAttendeeValue = this.attendeeCategoryTheme.find(x => x.name == `A-${c.Code}`);

                if(!foundAttendeeValue) {
                    foundAttendeeValue = {
                        name: `A-${c.Code}`,
                        value: '#aaa',
                        type: 'color'
                    };

                    this.attendeeCategoryTheme.push(foundAttendeeValue);
                }

                Vue.set(foundAttendeeValue, 'displayName', c.Name);

            });

            

            this.editSheet = (this.stylesheet || ':root { }');
            this.initialized = true;            
        },

        updateWithVibrantColor(property,vibrant){
            property.value = vibrant.value;
        },

        updateColor(property, value) {
            property.value = value.hex8;
        },

        updateShadowColor(property, value) {
            
            let newColor = value.hex8;
            let oldValue = property.value.split(' ');

            if(oldValue.length > 1) {
                oldValue.splice(oldValue.length - 1, 1, newColor);
            } else {
                oldValue = [newColor];
            }

            property.value = oldValue.join(' ')
        },

        updateTheme() {
            if(!this.editSheet  
                || !this.initialized) {
                return;
            }

            let indexOfRoot = this.editSheet.indexOf(":root");

            let newStyles = [];

            this.theme.forEach(element => {
                newStyles.push(`  --${element.name}: ${element.value};`);
            });

            this.agendaCategoryTheme.forEach(element => {
                newStyles.push(`  --${element.name}: ${element.value};`);
            });

            this.vendorCategoryTheme.forEach(element =>{
                newStyles.push(`  --${element.name}: ${element.value};`);
            });

            this.attendeeCategoryTheme.forEach(element =>{
                newStyles.push(`  --${element.name}: ${element.value};`);
            });

            if(indexOfRoot == -1) {
                // Root themes have never been set. Set them at the end.
                this.editSheet = `${this.editSheet}
:root \{
${newStyles.join('\r\n')}
\}`
            } else {
                let indexOfStartBracket = this.editSheet.indexOf('{', indexOfRoot);
                let indexOfEndBracket = this.editSheet.indexOf('}', indexOfStartBracket + 1);

                let startSheet = this.editSheet.slice(0, indexOfStartBracket + 2);
                let endSheet = this.editSheet.slice(indexOfEndBracket);

                this.editSheet = `${startSheet}
${newStyles.join('\r\n')}
${endSheet}`
            }

            this.$emit("update:stylesheet", this.editSheet);
        }
    },

    data() {
        return {
            initialized: false,
            editSheet: '',
            selectedThemeElement: null,
            previewForeColor: {value: 'initial'},
            previewBackColor: {value: 'initial'},
            previewBoxShadow: {value: 'initial'},
            previewTextShadow: {value: 'initial'},

            initial: {value: 'initial'},
            testImage: null,

            multiplyValues: [
                'normal',
                'multiply',
                'screen',
                'overlay',
                'darken',
                'lighten',
                'color-dodge',
                'color-burn',
                'difference',
                'exclusion',
                'hue',
                'saturation',
                'color',
                'luminosity'
            ],

            swatchGroups: [],

            agendaCategoryTheme: [

            ],
            vendorCategoryTheme: [],
            attendeeCategoryTheme:[],

            theme:[
                {"name": "text-color", "value": "#000", "type": "color"},
                {"name": "subtle-text-color", "value": "#555", "type": "color"},
                {"name": "light-text-color", "value": "#999", "type": "color"},
                {"name": "inverse-text-color", "value": "#fff", "type": "color"},
                {"name": "home-item-background", "value": "#203864", "type": "color"},
                {"name": "home-item-shadow", "value": "3px 2px 3px 0 rgba(0,0,0,0.15)", "type": "shadow"},
                {"name": "home-item-text", "value": "white", "type": "color"},
                {"name": "text-shadow", "value": "1px 1px 1px black", "type": "text-shadow"},
                {"name": "link-light-color", "value": "#D36582", "type": "color"},
                {"name": "link-dark-color", "value": "#203864", "type": "color"},
                {"name": "primary-color", "value": "#2B59C3", "type": "color"},
                {"name": "primary-color-dark", "value": "#203864", "type": "color"},
                {"name": "dark-border-color", "value": "#333", "type": "color"},
                {"name": "dark-bg-color", "value": "#333", "type": "color"},
                {"name": "med-border-color", "value": "#999", "type": "color"},
                {"name": "med-bg-color", "value": "#ccc", "type": "color"},
                {"name": "light-border-color", "value": "#D8D8D8", "type": "color"},
                {"name": "light-bg-color", "value": "#f7f7f7", "type": "color"},
                {"name": "title-bar-color", "value": "#203864", "type": "color"}, 
                {"name": "title-bar-text", "value": "white", "type": "color"},
                {"name": "menu-bg-color", "value": "white", "type": "color"},
                {"name": "modal-bg-color", "value": "rgba(0,0,0,0.6)", "type": "color"},
                {"name": "alert-light-bg-color", "value": "#DD0000", "type": "color"},
                {"name": "alert-dark-bg-color", "value": "darkred", "type": "color"},
                {"name": "home-background", "value": "white", "type": "color"},
                {"name": "home-item-background-multiply", "value": "multiply", "type": "blend"},
                {"name": "home-background-multiply", "value": "multiply", "type": "blend"},
                {"name": "webinar-bg-color", "value": "#303030", "type": "color"},           
                {"name": "webinar-text-color", "value": "#e3e3e3", "type": "color"},           
                {"name": "webinar-control-bar-color", "value": "#404040", "type": "color"},           
                {"name": "webinar-control-btn-color", "value": "#e3e3e3", "type": "color"},           
                {"name": 'full-bright-bg-color', "value": '#ffffff', 'type': 'color'},           
                {"name": 'full-dark-bg-color', "value": '#000000', 'type': 'color'},
                {"name": 'right-pane-shadow', "value": '-4px 2px 8px 0px #e4e4e4', 'type': 'shadow'}
            ]
        }
    },

    created() {
        this.rebuild();
    }
}
</script>