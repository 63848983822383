export default {
    data() {
        return {
            socialMediaTypes: [
                { Type: "Facebook", Name: "Facebook" },
                { Type: "LinkedIn", Name: "LinkedIn" },
                { Type: "Instagram", Name: "Instagram" },
                { Type: "Twitter", Name: "Twitter" },
                { Type: "Periscope", Name: "Periscope" },
                { Type: "YouTube", Name: "YouTube" }
            ],
            contactInfoTypes: [
                { Type: "Website", Name: "Website" },
                { Type: "Email", Name: "Email" },
                { Type: "Downloadable", Name: "Downloadable" },
                { Type: "TractusLink", Name: "Tractus Link" },
                { Type: "Section", Name: 'Section' },
                { Type: "Image", Name: "Image"}
           ],  
        }
    }
}