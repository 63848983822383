<template>
    <div class="container-fluid" v-if="!submitting">
        <div class="row">
            <div class="col">
                <h1>Outbound WebHooks</h1>


                <ul class="list-group">
                    <li class="list-group-item"
                        @click="addWebHook">
                        Add...
                    </li>
                    <li class="list-group-item"
                        @click="saveWebHooks">
                        Save Changes
                    </li>

                    <li v-for="hook in webhooks"
                        class="list-group-item"
                        :class="{'text-decoration-line-through' : hook.IsDeleted}">
                        <div class="row">
                            <div class="col-md">
                                <div class="form-floating">
                                    <input class="form-control" type="url" v-model="hook.Url" placeholder="URL">
                                    <label>WebHook URL</label>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <select class="form-select" 
                                            v-model="hook.HookTypeCode">
                                        <option :value="''">
                                            (Please Select...)
                                        </option>
                                        <option v-for="option in hookTypes"
                                                :key="option.Code"
                                                :value="option.Code">
                                            {{option.Name}}
                                        </option>
                                    </select>
                                    <label>WebHook Type</label>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <div class="form-check">
                                    <label class="form-check-label">
                                        <input class="form-check-input"
                                            type="checkbox"
                                            v-model="hook.IsEnabled">
                                        Enabled
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-auto">
                                <button @click="handleHookDelete(hook)"
                                        class="btn btn-danger">
                                    Delete
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="row">

                </div>
            </div>
        </div>
    </div>
    <div v-else
         class="container-fluid vh-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import Vue from 'vue';

export default {
    props: ['showCode'],

    mixins: [
        Token,
        Common,
    ],   

    data() {
        return {
            webhooks: [],
            hookTypes: [
                {
                    Name: 'Public Poll Submitted',
                    Code: "PublicPollSubmitted"
                },
                {
                    Name: "Finished Encoding a Video for HLS",
                    Code: "VideoFinishedHLSEncoding"
                },
                {
                    Name: "External Video Uploaded",
                    Code: "ExternalVideoReadyForEncode"
                },
                {
                    Name: "Attendee Created",
                    Code: "AttendeeCreated"
                }

            ]
        }
    },

    methods: {
        addWebHook() {
            this.webhooks.push({
                ShowCode: this.showCode,
                Code: null,
                HookTypeCode: '',
                Url: '',
                IsEnabled: true,
                IsDeleted: false
            });
        },

        handleHookDelete(hook) {
            if(!hook.Code) {
                let hookIndex = this.webhooks.indexOf(hook);

                if(hookIndex != -1) {
                    this.webhooks.splice(hookIndex, 1);
                }
            } else {
                hook.IsDeleted = !hook.IsDeleted;
            }
        },

        async saveWebHooks() {
            this.submitting = true;

            let result = await this.tryPost(`/api/webhooks/${this.showCode}`, JSON.stringify(this.webhooks), 'application/json');

            this.webhooks = result.Result;

            this.submitting = false;
        },

        async build() {
            this.submitting = true;

            let result = await this.tryGet(`/api/webhooks/${this.showCode}`);

            this.webhooks = result.Result;

            this.submitting = false;
        }
    },

    async created() {
        this.build();
    },
    
    // beforeDestroy() {
    //     if(this.modal){
    //         this.modal.dispose();
    //     }
    // },
}
</script>