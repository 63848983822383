import * as signalR from '@microsoft/signalr';

class TractusSignalRController {
    constructor(bus, apiUrl) {
        this.initialized = false;
        this.apiUrl = apiUrl;
        this.bus = bus;
        this.signalRConnectMode = 'disconnected';        
        this.signalRConnection = null;
        this.token = null;

        this.bus.$on('token-change', this.onTokenChanged);
    }
    
    onTokenChanged(token) {
        this.token = token;
    }

    onSignalRStateChange() {

        let connectionStateFormal = this.signalRConnectMode[0].toUpperCase() + this.signalRConnectMode.substr(1);

        let connectionStateMessage = '';

        if(this.signalRConnectMode == 'connected') {
            connectionStateMessage = 'You are connected to Tractus. Everything is working normally.';
        } else if(this.signalRConnectMode == 'reconnecting') {
            connectionStateMessage = 'We are trying to reconnect you to Tractus. Some functions such as chat may not work.';
        } else if(this.signalRConnectMode == 'connecting') {
            connectionStateMessage = 'We are trying to connect you to Tractus. Some functions such as chat may not work.';
        } else if(this.signalRConnectMode == 'disconnected') {
            connectionStateMessage = 'You have been disconnected from Tractus. We will try to reconnect you soon. Some functions such as chat may not work.';
        }

        this.bus.$emit('signalr-connection-state-change', this.signalRConnectMode, connectionStateMessage);
    }

    async initializeAsync(token) {
        this.token = token;

        if(this.initialized) {
            return;
        }

        this.signalRConnectMode = 'connecting';

        // Need to build the signalR connection
        this.signalRConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.apiUrl + '/hub/da', {
                accessTokenFactory: () => {
                    return this.token;
                }})
            .withAutomaticReconnect([0, 1000, 2000, 4000, 8000, 16000, 30000, 30000, 30000, 30000])
            // .configureLogging(signalR.LogLevel.Error)
            // .configureLogging(new TractusLogger(this.bus))
            .build();

        this.signalRConnection.onreconnecting(() => {
            this.signalRConnectMode = 'reconnecting';
            this.onSignalRStateChange();
        });

        this.signalRConnection.onreconnected(() => {
            this.signalRConnectMode = 'connected';
            this.onSignalRStateChange();
        });

        this.signalRConnection.onclose((ex) => {
            this.signalRConnectMode = 'disconnected';
            this.onSignalRStateChange();
        });

        this.signalRConnection.on('ControlChannelMessage', this.onControlChannelMessage);
        
        await this.ensureSignalRConnected();
    }    

    async ensureSignalRConnected() {
        if(this.initialized) {
            return;
        }

        this.initialized = true;

        try {
            await this.signalRConnection.start();

            this.signalRConnectMode = 'connected';

            this.onSignalRStateChange();

        } catch(ex) {
            if(ex == "Error: Cannot start a HubConnection that is not in the 'Disconnected' state.") {
                console.warn("Already connected to SignalR - aborting ensure.");
                return;
            }
            this.signalRConnectMode = 'disconnected';
            console.error('ENSURE SIGNALR CONNECTED ==---', ex);
            setTimeout(this.ensureSignalRConnected, 5000);
        }
    }

    async onControlChannelMessage(message) {
        this.bus.$emit('Control-Channel-Message', message);
    }

    destroy() {
        this.signalRConnection.onclose(() => {});

        this.signalRConnection.off('ControlChannelMessage', this.onControlChannelMessage);
        this.bus.$off('token-change', this.onTokenChanged);

        this.signalRConnection.stop();

        this.initialized = false;
    }

    async unsubscribeFromShowAsync(showCode) {
        if(!this.initialized || this.signalRConnectMode != 'connected') {
            return;
        }

        await this.signalRConnection.invoke('UnsubscribeFromShowNonUserAsync', showCode);
    }

    async subscribeToShowAsync(showCode) {
        if(!this.initialized || this.signalRConnectMode != 'connected') {
            return;
        }

        await this.signalRConnection.invoke('SubscribeToShowNonUserAsync', showCode);
    }

 };

export { TractusSignalRController };
