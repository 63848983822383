<template>
    <div v-if="show"
         class="container-fluid">

        <div class="row align-items-center mb-2">
            <div class="col-12">
                <div class="form-floating mb-3">
                    <input class="form-control"
                        v-model="show.Name"
                        placeholder="Name">
                    <label>
                        Name
                    </label>
                </div>
            </div>

            <div class="col">
                <div class="form-floating">
                    <input class="form-control"
                        v-model="show.SupportEmail"
                        placeholder="Support E-Mail Addresss">
                    <label>
                        Support E-Mail Address
                    </label>
                </div>
            </div>
            <div class="col">
                <div class="form-floating">
                    <input class="form-control"
                        v-model="showCode"
                        :readonly="!generateCode"
                        placeholder="Code">
                    <label>
                        Code
                    </label>
                </div>
            </div>
            <div class="col-auto">
                <div class="form-check">
                    <label class="form-check-label">
                        <input type="checkbox" 
                           class="form-check-input"
                           v-model="show.IsPublicShow" />
                        Public
                    </label>
                </div>
            </div>
            <div class="col-auto">
                <div class="form-check">
                    <label class="form-check-label">
                        <input type="checkbox" 
                           class="form-check-input"
                           v-model="show.EternalShow" />
                        Community
                    </label>
                </div>
            </div>
        </div>

        <div class="row" 
             v-if="!show.EternalShow">
            
            <div class="col-12">
                <h5>Dates</h5>
            </div>
           
            <div class="col">
                <div class="d-flex">
                    <i class="bi bi-calendar pe-1 d-flex align-items-center"></i>
                    <ctk-date-time-picker v-model="show.StartDateTime"
                                        format="YYYY-MM-DDTHH:mmZ"
                                        minuteInterval="5"
                                        :disabled="submitting"></ctk-date-time-picker>
                    <p class="mx-3 mb-0 d-flex align-items-center"> to </p>
                    <i class="bi bi-calendar pe-1 d-flex align-items-center"></i>
                    <ctk-date-time-picker v-model="show.EndDateTime"
                                    format="YYYY-MM-DDTHH:mmZ"
                                    minuteInterval="5"
                                    :disabled="submitting"></ctk-date-time-picker>
                </div>
            </div>
        </div>

        <div class="row"
             v-if="show.Code">
            <div class="col-12">
                <h5>Show Icon</h5>
            </div>
            <div class="col-auto">
                <div class="show-image border border-dark rounded">
                    <img v-if="show.ShowHeaderImagePath" @click="onChooseHeaderImage" 
                        :src="show.ShowHeaderImagePath">
                    <div v-else class="no-image-preview" @click="onChooseHeaderImage">
                        No Image Selected
                    </div>

                </div>
                <file-picker ref="picker" 
                        @fileSelected="onFileSelected" 
                        :showCode="show.Code"
                        type="image"
                        :imagePath="show.ShowHeaderImagePath"/>

            </div>
            <div class="col">
                <slot></slot>
            </div>
        </div>

    </div>    
</template>
<style scoped>
.show-image {
    width: 250px;
    height: 250px;
    min-width: 250px;
    max-width: 250px;
    min-height: 250px;
    max-height: 250px;
    overflow: hidden;
}

.show-image > * {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 1px solid #ddd;
}

.img-icon {
    height: 200px;
    max-height: 200px;
    min-height: 200px;
}
.no-image-preview {
    cursor: pointer;
    background: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 1px solid #999;
}

.show-image-preview {
    cursor: pointer;
    max-height: 200px;
    align-self: center;
    border: 1px solid #eee;
}
</style>
<script>
import Vue from 'vue'

export default {
    props: ['show', 'generateCode', 'submitting'],

    computed: {
        showCode: {
            get() {
                return this.show.Code;
            },
            set(newValue) {
                this.show.Code = newValue?.toUpperCase()
            }
        }
    },

    methods: {
        onChooseHeaderImage(){
            // console.log(this.show);
            this.$refs.picker.openPicker();
        },

        setEternalShow(mode) {
            Vue.set(this.show, 'EternalShow', mode);
        },

        onFileSelected(fileName){
            this.show.ShowHeaderImagePath = fileName;
        },
    },

}
</script>