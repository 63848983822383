<template>
    <loading v-if="submitting">
    </loading>
    <div class="container-fluid scrolling-columns" v-else>
        <button class="btn btn-primary" @click="onSave">
            Save
        </button>
        <template v-for="(category, categoryCode) in vendors">
            <h4 class="sticky-top p-3 m-0 bg-body-tertiary border border-bottom-0">
                <div class="btn-group">
                    <button class="btn btn-primary" type="button" @click="onSelectAll(categoryCode)">Select All</button>
                    <button class="btn btn-primary" type="button" @click="onSelectAllAppointment(categoryCode)">Select All</button>
                </div>
                {{getCategoryName(categoryCode)}} ({{category.length}})
            </h4>
            <div class="list-group">
                <div v-for="item in category"
                        class="list-group-item list-group-item-action"
                        :key="item.Entity.Code">
                    <div class="row">
                        <div class="col-auto">
                            <session-vendor-item :session="item.Entity" 
                            :vendor="true" 
                            :showDetails="showDetails"/>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-auto">
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="checkbox" 
                                        v-model="item.IsBookable"
                                        class="form-check-input"/>
                                        Allow Bookings
                                </label>
                            </div>
                        </div>
                        <div class="col-auto">
                                <label class="form-check-label">
                                    <input type="checkbox" 
                                        v-model="item.CanScheduleAppointments"
                                        class="form-check-input"/>
                                        Can Schedule Appointments
                                </label>
                        </div>
                        <div class="col-auto">
                            <label class="form-check-label">
                                <input type="checkbox"
                                       v-model="item.CanHavePageMessages"
                                       class="form-check-input">
                                Can Have DMs
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import SessionContactInfo from '../models/VendorAdditionalInfo'
import Vendor from '../models/Vendor'
export default {
    
    props: ['showDetails', 
            'showCode'],

    mixins: [
        Token,
        Common
    ],

    methods: {  

        onSelectAll(categoryCode){
            for(var i = 0; i < this.vendors[categoryCode].length; i++){
                this.vendors[categoryCode][i].IsBookable = true;
            }
        },

        onSelectAllAppointment(categoryCode) {
            this.onSelectAll(categoryCode);

            for(var i = 0; i < this.vendors[categoryCode].length; i++){
                this.vendors[categoryCode][i].CanScheduleAppointments = true;
            }
        },

        onSelectAllDM(categoryCode) {
            this.onSelectAll(categoryCode);

            for(var i = 0; i < this.vendors[categoryCode].length; i++){
                this.vendors[categoryCode][i].CanHavePageMessages = true;
            }
        },

        getCategoryName(code) {
            let category = this.showDetails.VendorCategories.find(x => x.Code == code);

            if(!category) {
                return code;
            }

            return category.Name;
        },

        updateSelectedVendor(item) {
            this.selectedVendor = null;
            Vue.nextTick(() => this.selectedVendor = item);
        },

        async onSave() {
            try{

                var toUpdate = [];
                for(var vendorType in this.vendors){
                    for(var vendor of this.vendors[vendorType]){
                        toUpdate.push({
                            'EntityTypeCode': 'VENDOR',
                            'EntityCode': vendor.Entity.Code,
                            'IsBookable': vendor.IsBookable,
                            'CanScheduleAppointments': vendor.CanScheduleAppointments,
                            'CanHavePageMessages': vendor.CanHavePageMessages
                        });
                    }
                }
                
                await this.tryPost(`/api/appointment/admin/${this.showCode}/bookable`, JSON.stringify(toUpdate), 'application/json');
            }
            catch(ex){
                console.log(ex);
            }
        },

        onCancel() {
            this.selectedVendor = null;
        },

        async build() {
            this.submitting = true;
                       
            try {
                let result = await this.tryGet(`/api/show/${this.showCode}/vendors`);
                let vendors = result.Result;
                let entityTypeCode = 'VENDOR';
                let bookableResult = await this.tryGet(`/api/appointment/admin/${this.showCode}/bookable/${entityTypeCode}`);
                let bookableVendors = bookableResult.Result;

                for(var type in vendors){
                    let vendorsToAdd = []
                    for(var vendor of vendors[type]){
                        let isBookable = bookableVendors.find(x => x.EntityCode == vendor.Code);
                        vendorsToAdd.push({
                            'Entity': vendor,
                            'IsBookable': isBookable && isBookable.IsBookable,
                            'CanScheduleAppointments': isBookable && isBookable.CanScheduleAppointments,
                            'CanHavePageMessages': isBookable && isBookable.CanHavePageMessages
                        });
                    }
                    Vue.set(this.vendors,type,vendorsToAdd);
                }

                this.originalVendor = this.cloneObject(this.vendors);

            } catch(ex) {
                console.log(ex);
                alert("Could not load pages. Try again later.");
            }
            
            this.submitting = false;
        },
    },

    data() {
        return {
            vendors: {},
            selectedVendor: null,
            originalVendor: {},
        }
    },

    mounted: function() {
        this.build();
    },
}
</script>