<template>
    <form class="form-group">
        <div class="modal fade" 
            id="deleteVendorWarning"
             ref="deleteVendorWarn">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="modal-title">
                            Delete Vendor
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure you want to delete {{vendor.Name}}? This action can't be undone.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-secondary" 
                                data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                                @click="onDelete">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-between mt-1">
            <button type="button"
                    class="btn btn-secondary"
                    @click="OnCancel">
                Cancel
            </button>

            <button type="button"
                    class="btn btn-danger"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteVendorWarning">
                Delete
            </button>

            <div class="btn-group">
                <button type="button"
                        class="btn btn-primary"
                        @click="onSave">
                    Save
                </button>
            </div>
        </div>
        
        
        <div class="row mt-1">
            <div class="col-sm-4">
                <div class="profile-photo-preview"
                    @click="onChoose">
                    <img :src="editVendor.ContactInfo.PhotoUrl"
                            class="img-fluid"
                            :acceptFileType="'image/*'"
                            v-if="editVendor.ContactInfo.PhotoUrl">
                </div>
                <file-picker ref="picker" 
                            @fileSelected="onFileSelected" 
                            :showCode="show.Code"
                            type="image"
                            :imagePath="editVendor.ContactInfo.PhotoUrl"/>
                <i v-if="editVendor.ContactInfo.PhotoUrl !== '' && editVendor.ContactInfo.PhotoUrl != null  " @click="clearImage" class="ms-auto bi bi-x-circle"></i>
                <div class="mt-2" v-if="editVendor.DiscussionWebinarStatus != 'Public'">
                    <label :class="{'fw-bold': editVendor.ShowPublicChat  }" 
                            class="ps-3 form-check-label">
                            <input type="checkbox"
                                    v-model="editVendor.ShowPublicChat  ">
                        Show Public Chat
                    </label>
                </div>
            </div>
            <div class="col">
                <div class="form-floating mt-2">
                    <input  :disabled="submitting" 
                        class="mb-2 form-control"
                        v-model="editVendor.Name" />
                        <label>Page Title</label>
                </div>
                
                <div class="form-floating mt-2"> 
                    <textarea   v-model="editVendor.ContactInfo.Bio" 
                                class="form-control form-control-sm" 
                                aria-label="With textarea">
                    </textarea>  
                    <label>Bio</label>
                </div>
                <div class="form-floating mt-2"> 
                    <textarea   v-model="editVendor.Headlines" 
                                class="form-control form-control-sm" 
                                aria-label="With textarea">
                    </textarea>  
                    <label>Headline</label>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-floating mt-2">
                            <select :disabled="submitting" 
                                    class="form-control" 
                                    v-model="editVendor.Category">
                                <option selected :value="null">(Please Select...)</option>
                                <option v-for="item in show.VendorCategories" :value="item">{{ item.Name }}</option>
                            </select>
                            <label>Category</label>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-floating mt-2">
                            <input :disabled="submitting || editVendor.Id != '0'" 
                                class="mb-2 form-control"
                                v-model="editVendor.Code" />
                            <label>Page Code</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4" v-for="category in subCategories" v-bind:key="category.Code">
                <div class="form-check">
                    <label 
                        class="form-check-label">
                        <input v-model="category.Selected"
                            class="form-check-input" 
                            type="checkbox">{{category.Name}}
                    </label>
                </div>
            </div>
        </div>
        <div class="form-floating mt-2">
            <input :disabled="submitting" 
                    class="form-control form-control-sm"
                    v-model="editVendor.Location" />
            <label>Location</label>
        </div>
        <div class="row mt-2">
            <div class="col">
                <div class="form-floating">
                    <select v-model="editVendor.DiscussionCode" class="form-select">
                        <option :value="null">(Please Select...)</option>
                        <option v-for="destination in discussions" :value="destination.Code" :key="destination.Code">
                            {{destination.Name}}
                        </option>
                    </select>
                    <label>
                        Linked Discussion Area
                    </label>
                </div>
            </div>
            <div class="col-auto">
                <div class="d-flex h-100 btn-group">
                    <button class="btn btn-outline-primary"
                            type="button"
                            @click="addDiscussionForVendor">
                        Add
                    </button>
                    <button class="btn btn-outline-primary"
                            type="button"
                            @click="syncModeratorsForVendor">
                        Sync Moderators
                    </button>
                </div>
            </div>
        </div>  
        
        <div class="form-floating mt-2">
            <select v-model="editVendor.DiscussionWebinarStatus" class="form-select">
                <option :value="null">None</option>
                <option value="Private">Private</option>
                <option value="Public">Public</option>
            </select>
            <label>
                Webinar Status
            </label>
        </div>

        <record-permissions :showCode="show.Code"
                            :roomCode="editVendor.DiscussionCode">

        </record-permissions>

        <hr>

        <div class="mb-2">
            <button type="button" @click.prevent="onAddContactInfo" id="addBtn" class="btn btn-primary">
                <i class="bi bi-plus-square ps-1"></i>&nbsp;Add Contact Info/Social Media
            </button>
        </div>

        <vue-slicklist :useDragHandle="true" 
                       v-model="editVendor.ContactInfo.ContactInfo" 
                       lockAxis="y"
                       v-if="tractusLinkDestinations">
            <vue-slickitem class="row" 
                            v-for="(contactInfo, i) in editVendor.ContactInfo.ContactInfo" 
                            :key="i" 
                            :index="i">
                <i class="col-auto bi-grip-vertical" v-handle></i>
                <div class="col">
                    <contact-info-editor :contactInfo="contactInfo"
                                        :selectTypes="contactInfoTypes"
                                        :submitting="submitting"
                                        :show="show"
                                        v-on:delete-contact-info="onDeleteContactInfo(editVendor.ContactInfo.ContactInfo, contactInfo)"
                                        :isContact="true"
                                        :destinations="tractusLinkDestinations">
                    </contact-info-editor>
                </div>
            </vue-slickitem>
        </vue-slicklist>
        <loading v-else />

        <div class="mb-2">
            <button type="button" @click.prevent="onAddContactInfo" id="addBtn" class="btn btn-primary">
                <i class="bi bi-plus-square ps-1"></i>&nbsp;Add Contact Info/Social Media
            </button>
        </div>
        <hr>
        <h5>List Template Details</h5>
        <label>List Cover Photo (For Deluxe)</label>
        <div>
            <div class="profile-photo-preview" @click="onChooseCover">
                <img :src="editVendor.ListCoverUrl"
                        class="img-fluid"
                        :acceptFileType="'image/*'"
                        v-if="editVendor.ListCoverUrl">
            </div>
            <file-picker ref="pickerCoverUrl" 
                        @fileSelected="onFileSelectedCover" 
                        :showCode="show.Code"
                        type="image"
                        :imagePath="editVendor.ListCoverUrl"/>
            <i v-if="editVendor.ListCoverUrl !== '' && editVendor.ListCoverUrl != null" @click="editVendor.ListCoverUrl = null" 
               class="ms-auto bi bi-x-circle"></i>
        </div>
        <div class="form-floating mt-2">
            <textarea v-model="editVendor.ListTemplate"
                  class="form-control form-control-sm font-monospace"
                  rows="4">
            </textarea>
            <label>List HTML</label>
        </div>

        <hr>

        <media-information v-if="editVendor.PrimaryMedia" 
                           :mediaInformation.sync="editVendor.PrimaryMedia"
                           :show="show">
        </media-information>

        <div v-if="validateErrors && validateErrors.length > 0" class="mt-1 alert alert-danger text-start" role="alert">
            Oops! It looks like something happened. Please double-check and try again.
            <ul>
                <li v-for="error in validateErrors">
                    {{ error }}
                </li>
            </ul>
        </div>

    </form>
</template>
<script>
import Vue from 'vue'
import Token from '../authentication/token'
import Common from '../common'
import CommonTypes from '../common.types'
import loading from '../loading.vue'


export default {
    components: { loading },
    props: ['vendor', 'show'],
    mixins: [
        Token,
        Common,
        CommonTypes
    ],

    data() {
        return {
            editVendor: null,
            validCode:true,
            uploadBus: new Vue(),
            discussions:[],
            subCategories: [],
        }
    },

    async created() {
        console.log(this.vendor);
        this.editVendor = this.cloneObject(this.vendor);
        await this.getTractusLinkDestinationsAsync(this.show.Code);

        if(!this.editVendor.Headlines){
            Vue.set(this.editVendor,'Headlines',"");
        }

        if(!this.editVendor.ShowPublicChat ) {
            Vue.set(this.editVendor, 'ShowPublicChat', false);
        }

        if(!this.editVendor.SubCategoryCodes) {
            Vue.set(this.editVendor, 'SubCategoryCodes', []);
        }

        if(!this.editVendor.ListTemplate) {
            Vue.set(this.editVendor, 'ListTemplate', null);
        }

        if(!this.editVendor.ListCoverUrl) {
            Vue.set(this.editVendor, 'ListCoverUrl', null);
        }

        if(!this.editVendor.DiscussionCode) {
            Vue.set(this.editVendor, 'DiscussionCode', null);
        }

        if(!this.editVendor.DiscussionWebinarStatus) {
            Vue.set(this.editVendor, 'DiscussionWebinarStatus', null);
        }

        for(let i = 0; i < this.show.VendorCategories.length; i++) {
            let code = this.show.VendorCategories[i].Code;

            this.subCategories.push({
                Selected: this.editVendor.SubCategoryCodes.find(x => x == code) != undefined,
                Code: code,
                Name: this.show.VendorCategories[i].Name
            });
        }

        if(typeof this.editVendor.PrimaryMedia === 'undefined'
            || this.editVendor.PrimaryMedia == null){
            Vue.set(this.editVendor, "PrimaryMedia", {
                Code: this.getNewGuid(),
                Uri: "",
                MediaType: "Video",
                AutoPlay: false,
                ThumbnailUri: "",
                Title: "",
                Description: "",
                PlayAfterExit: false
            });
        }

        if(this.editVendor.Category != null && this.show.VendorCategories != null) {
            for(var i = 0; i < this.show.VendorCategories.length; i++) {
                var category = this.show.VendorCategories[i];
                if(category.Code === this.editVendor.Category.Code) {
                    this.editVendor.Category = category;
                    break;
                }
            }
        }

        this.getDiscussions();

        this.uploadBus.$on('uploadSuccess', r => {
            this.editVendor.ContactInfo.PhotoUrl = r.Result;
        });
    },

    methods: {
        async addDiscussionForVendor() {
            let discussion = {
                Id: '0',
                ShowCode: this.show.Code,
                ParticipantRoles: {},
                TypeCode: "LiveQa",
                Name: `${this.editVendor.Name}`,
                AllowAnonymous: true,
                AllowPost: true,
                InviteOnly: false,
                NotifyParticipants: false,
                AllowCall: false,
                WebinarFormat: true,
                MaxCallers: 50,
                VisibleToCategories: []
            };

            Vue.set(this.editVendor, 'DiscussionWebinarStatus', 'Private');

            await this.updateDiscussionWithModerators(discussion);
        },

        async syncModeratorsForVendor() {
            if(!this.editVendor.DiscussionCode) {
                return;
            }

            let discussion = this.discussions.find(x => x.Code == this.editVendor.DiscussionCode);

            if(!discussion) {
                return;
            }

            await this.updateDiscussionWithModerators(discussion);
        },

        async updateDiscussionWithModerators(discussion) {
            discussion.ParticipantRoles = {};
            discussion.Name = `${this.editVendor.Name}`;
            discussion.TypeCode = 'LiveQa';

            let attendees = this.editVendor.ContactInfo.ContactInfo.filter(x => 
                x.Type && x.Type == 'TractusLink' 
                && x.Value && x.Value.indexOf('tractus://Attendee') != -1)
                .map(x => {
                    return x.Value.split('c=')[1];
                });

            for(let i = 0; i < attendees.length; i++) {
                let code = attendees[i];

                discussion.ParticipantRoles[code] = "Administrator";
            }

            try {
                let result = await this.tryPost("/api/discussion", JSON.stringify(discussion), 'application/json');
                await this.getDiscussions();

                Vue.nextTick(() => {
                    Vue.set(this.editVendor, 'DiscussionCode', result.Result.Code);
                })
            } catch(ex) {
                console.error(ex);
                alert("Could not save discussion.");
            }
        },

        async getDiscussions(){
            try{
                let r = await this.tryGet(`/api/discussions/show/${this.show.Code}`);
                this.discussions = r.Result;
            }
            catch(ex){
                console.log(ex);
            }
        },
        getNewGuid() { 
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) { 
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8); 
                return v.toString(16); 
            }); 
        },

        onAddContactInfo(value, property, type) {
            var toAdd = {
                Details: "",
                DisplayText: "",
                Type: ""
            };

            if(!this.editVendor.ContactInfo.ContactInfo) {
                Vue.set(this.editVendor.ContactInfo, 'ContactInfo', []);
            }             

            if(value && property) {
                toAdd[property] = value;
                toAdd.Type = 'Website';
                value = value.trim();
            }

            if(type) {
                toAdd.Type = type;

                if(type == 'Email' && value) {
                    toAdd.DisplayText = value;
                }
            }

            this.editVendor.ContactInfo.ContactInfo.push(toAdd);
            this.$emit('update:vendor', this.editVendor);
        },

        //also used on poll.transfer.to.page
        flashProperty(value, propertyName) {
            value = value.trim();

            Vue.set(this.editVendor, propertyName, value);

            if(propertyName == 'Name' && !this.editVendor.Code) {
                let newCode = value.split(' ').filter(x => x.length > 0).map(x => x[0].toUpperCase()).filter(x => x >= 'A' && x <= 'Z').join('');

                let totalCountOfVendors = 1;
                for(const category in this.show.Vendors) {
                    console.log(category)
                    totalCountOfVendors += this.show.Vendors[category].length;
                }

                newCode = `${newCode}${totalCountOfVendors.toString().padStart(6, '0')}`
                this.editVendor.Code = newCode;
            }

            this.$emit('update:vendor', this.editVendor);
        },

        //also used on poll.transfer.to.page
        flashMediaProperty(value, propertyName) {
            value = value.trim();
            Vue.set(this.editVendor.PrimaryMedia, propertyName, value);
            this.$emit('update:vendor', this.editVendor);
        },

        //also used on poll.transfer.to.page
        flashContactInfoProperty(value, propertyName) {
            value = value.trim();
            Vue.set(this.editVendor.ContactInfo, propertyName, value);
            this.$emit('update:vendor', this.editVendor);
        },

        //also used on poll.transfer.to.page
        flashContactInfoItemProperty(value, index, propertyName) {
            value = value.trim();
            Vue.set(this.editVendor.ContactInfo.ContactInfo[index], propertyName, value);
            this.$emit('update:vendor', this.editVendor);
        },

        onDeleteContactInfo(deleteFrom,toBeDeleted) {
            deleteFrom.splice(deleteFrom.indexOf(toBeDeleted), 1);
            this.$emit('update:vendor', this.editVendor);
        },

         validateForm: function() {

            this.validateErrors = [];
            this.validCode=true;

           if(this.editVendor.PrimaryMedia.Uri){
                if(!this.editVendor.PrimaryMedia.Uri.replace(/\s/g, '').length){
                    this.validateErrors.push("Uri cannot contain only spaces.");
                } 
                if(this.editVendor.PrimaryMedia.Title && !this.editVendor.PrimaryMedia.Title.replace(/\s/g, '').length){
                    this.validateErrors.push("Video Title cannot contain only spaces.");
                }
            }

            if(!this.editVendor.Code) {
                this.validateErrors.push("Company Code cannot be blank.");
            }
            else{
                var letters = /^[0-9a-zA-Z]+$/;
                if(this.editVendor.Code && !this.editVendor.Code.match(letters)){
                    // this.validateErrors.push("Company Code can only have numbers and letters.");
                }

                //Check if session code is unique 
                if(this.editVendor.Code && this.editVendor.Id === '0'){
                    console.log(this.tractusLinkDestinations['VENDORS']);
                    console.log(this.editVendor.Code);
                    for(var j = 0; j < this.tractusLinkDestinations['VENDORS'].length; j++){
                        console.log(this.tractusLinkDestinations['VENDORS'][j]);
                        if(this.editVendor.Code === this.tractusLinkDestinations['VENDORS'][j].Code){
                            this.validCode = false;
                        }
                    }
                }
            }

            

            var str = this.editVendor.Name;
            if (this.editVendor.Name && !str.replace(/\s/g, '').length) {
                this.validateErrors.push("Company Name cannot be only spaces.");
            }
            if(!this.editVendor.Name) {
                this.validateErrors.push("Company Name cannot be blank.");
            }
            
            if(!this.editVendor.Category || !this.editVendor.Category.Code){
                this.validateErrors.push("No Company Category has been selected.");
            }
            if(!this.validCode){
                this.validateErrors.push("Code already used.");
            }
            return this.validateErrors.length === 0;
        },

        clearImage(){
            this.editVendor.ContactInfo.PhotoUrl= '';
        },
        onChoose(){
            // console.log(this.show);
            this.$refs.picker.openPicker();
        },


        onFileSelected(fileName){
            this.editVendor.ContactInfo.PhotoUrl = fileName;
        },

        onFileSelectedCover(fileName){
            this.editVendor.ListCoverUrl = fileName;
        },

        onChooseCover(){
            // console.log(this.show);
            this.$refs.pickerCoverUrl.openPicker();
        },


        async onDelete() {
            this.submitting = true;
            
            this.editVendor.Code = this.editVendor.Code;
            this.editVendor.Show = {
                Id: this.show.Id,
                Code: this.show.Code
            };

            try{
                let r = await this.tryDelete(`/api/vendor/${this.show.Code}/${this.editVendor.Code}`);
                    
                if(r.Errors.length === 0) {
                    this.$emit('saved', this.vendor);
                }
            }
            catch(ex){
                console.log(ex);
            }
            
            this.submitting = false;
        },
        async onSave() {
            this.submitting = true;
            
            if(!this.validateForm()) {
                this.submitting = false;
                return;
            }

            this.editVendor.Code = this.editVendor.Code;
            this.editVendor.Show = {
                Id: this.show.Id,
                Code: this.show.Code
            };

            this.editVendor.SpeakerInfo = [];
            this.editVendor.SubCategoryCodes = [];

            for(let i = 0; i < this.subCategories.length; i++) {
                let option = this.subCategories[i];
                if(!option.Selected) {
                    continue;
                }

                this.editVendor.SubCategoryCodes.push(option.Code);
            }

            try{
                let r = await this.tryPost('/api/vendor', JSON.stringify(this.editVendor), 'application/json');
                    
                if(r.Errors.length === 0) {
                    this.$emit('saved', r.Result);
                }
            }
            catch(ex){
                console.log(ex);
            }
            this.submitting = false;
            
        },
        OnCancel(){
            this.submitting = false;
            this.$emit('cancel');
        },
    },
}
</script>
