<template>
    <div v-if="!submitting">
        <div class="row pt-2 pb-2 border-bottom sticky-top bg-dark">
            <div class="col-auto" v-if="invites.length == 0">
                <div class="d-flex align-items-center h-100">
                    {{ invites.length }} invites
                </div>
            </div>
            <div class="col" v-else> 
                <div class="d-flex align-items-center h-100">
                    {{ invites.length }} invites, {{ totalRedemptions }} redeemed ({{ Math.ceil((totalRedemptions / invites.length) * 100) }}%)
                </div>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary"
                        @click="emitCancel">
                    Results
                </button>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary"
                        @click="uploadCsv">
                    Upload CSV...
                </button>
            </div>
            <div class="col-auto">
                <div class="btn-group">
                    <button class="btn"
                            :class="{'active btn-primary': editCustomFieldMapping, 'btn-secondary': !editCustomFieldMapping}"
                            @click="editCustomFieldMapping = !editCustomFieldMapping">
                        Edit Fields
                    </button>
                </div>
            </div>

            <div class="col-auto">
                <div class="btn-group">
                    <button class="btn btn-primary"
                            @click="build">
                        Refresh
                    </button>

                    <button class="btn btn-primary"
                            @click="sendAllInvites">
                        Send to All
                    </button>
                    <button class="btn btn-primary"
                            @click="sendToUnsent">
                        Send to Unsent
                    </button>

                    <button class="btn btn-primary"
                            @click="saveInvites">
                        Save
                    </button>

                </div>
            </div>
            <div class="col-auto">
                <button class="btn btn-primary"
                        @click="addInvite">
                    Add...
                </button>
            </div>
        </div>
        <input type="file" style="display:none;" @change="fileChange($event.target.name, $event.target.files)" ref="csvFile">

        <table class="table table-hover table-striped tPollInviteListable-sm table-responsive response-table">
            <thead class="atn-header border-bottom border-dark bg-body-tertiary sticky-top">
                <tr>
                    <th width="125">
                        Invite Code
                    </th>
                    <th>
                        Name
                    </th>
                    <th>
                        To E-Mail
                    </th>
                    <th>
                        CC
                    </th>

                    <th v-for="(field, ix) in customFields">

                        <template v-if="editCustomFieldMapping">
                            <div class="row">
                                <div class="col">
                                    <input v-model="field.Name"
                                        @input="updateCustomFieldDetails(ix)"
                                        class="form-control form-control-sm">
                                </div>
                                <div class="col">
                                    <select v-model="field.MappedToQuestion"
                                            @change="updateCustomFieldDetails(ix)"
                                            class="form-select form-select-sm">
                                        <option :value="''">None</option>
                                        <option v-for="question in poll.Questions"
                                                :key="question.Code"
                                                :value="question.Code">
                                            {{ question.Name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-auto">
                                    
                                    <button class="btn btn-outline btn-outline-danger btn-sm"
                                            @click="removeCustomField(ix)">
                                        <i class="bi bi-trash"></i>    
                                    </button>

                                </div>
                            </div>
                        </template>
                        <template v-else>
                            {{ field.Name }}
                        </template>
                    </th>
                    <th>
                        <button class="btn btn-outline btn-outline-primary btn-sm"
                                @click="addCustomField('')">
                            <i class="bi bi-plus"></i>    
                        </button>
                    </th>
                    <th>
                        <div class="row">
                            <div class="col-auto">
                                Template
                            </div>
                            <div class="col">
                                <select class="form-select form-select-sm"
                                        @change="onSelectGlobalTemplateChange">
                                    <option disabled>Select New Template...</option>
                                    <option :value="''">(Clear)</option>    
                                    <option v-for='template in templates'
                                            :value="template.Code">{{ template.Subject }}</option>  
                                </select>
                            </div>
                        </div>
                    </th>
                    <th>
                        Last Emailed
                    </th>
                    <th>
                        Last Update
                    </th>
                    <th>
                        Initial Redeem
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>                
            </thead>
            <tbody>
                <tr v-for="invite in invites">
                    <td>
                        <input class="form-control form-control-sm"
                               :disabled="!invite._IsNew"
                               v-model="invite.InviteCode"
                               @input="triggerModified(invite)">
                    </td>
                    <td>
                        <input class="form-control form-control-sm"
                            v-model="invite.RecipientName"
                            @input="triggerModified(invite)">
                    </td>
                    <td>
                        <input class="form-control form-control-sm"
                            v-model="invite.ToEmailAddress"
                            @input="triggerModified(invite)">
                    </td>
                    <td>
                        <input class="form-control form-control-sm"
                            v-model="invite.CcEmailAddresses"
                            @input="triggerModified(invite)">
                    </td>

                    <td v-for="field in invite.Fields">
                        <input v-model="field.Value"
                                class="form-control form-control-sm">
                    </td>
                    <td>

                    </td>
                    <td>
                        <select v-model="invite.EmailTemplateCode"
                                class="form-select form-select-sm"
                            @input="triggerModified(invite)">
                            <option :value="''">(No Email)</option>    
                            <option v-for='template in templates'
                                    :value="template.Code">{{ template.Subject }}</option>    
                        </select>
                    </td>
                    <td>
                        <span v-if="invite.LastEmailDateTime">
                            {{ invite.LastEmailDateTime | moment('L LT') }}                        
                        </span>
                    </td>
                    <td>
                        <span v-if="invite.LastSubmitDateTime">
                            {{ invite.LastSubmitDateTime | moment('L LT') }}                        
                        </span>
                    </td>
                    <td>
                        <span v-if="invite.RedeemedDateTime">
                            {{ invite.RedeemedDateTime ? '✅' : '' }}
                            {{ invite.RedeemedDateTime | moment('L LT') }}                        
                        </span>
                    </td>                    
                    <td>
                        <div class="btn-group">
                            <button class="btn btn-sm btn-primary"
                                    @click="sendEmailToInvite(invite)">
                                <i class="bi bi-envelope"></i>
                            </button>
                            <a class="btn btn-sm btn-success"
                               target="_blank"
                               :href="getPollLink(invite)">
                                <i class="bi bi-share"></i>
                            </a>
                            <button class="btn btn-sm btn-danger"
                                    @click="deleteInvite(invite)">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>    

        <div id="flashDialog"
             ref="flashCsvDialog"
             class="modal fade">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Import Records ({{toImport.length}})</h5>                    
                        <button type="button" 
                                class="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close">
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>
                                        CSV Column
                                    </th>
                                    <th>
                                        Mapped To...
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="col in columnsToMap">
                                    <td>
                                        {{col.ColumnName}}
                                    </td>
                                    <td>
                                        <select class="form-control" v-model="col.Destination">
                                            <option :value="''">
                                                (Not Mapped)
                                            </option>
                                            <option :value="'CUSTOM_NEW'">
                                                (Create New Field)
                                            </option>
                                            <option value="InviteCode">
                                                Invite Code
                                            </option>
                                            <option value="RecipientName">
                                                Recipient Name
                                            </option>
                                            <option value="ToEmailAddress">
                                                To Email
                                            </option>
                                            <option value="CcEmailAddresses">
                                                CC Email
                                            </option>
                                            <option :value="`CUSTOM_${field.Name}`"
                                                    v-for="field in customFields">
                                                {{ field.Name }}
                                            </option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="form-floating">
                            <select v-model="templateForEmail"
                                    class="form-select form-select-sm">
                                <option :value="''">
                                    (No Email Template)
                                </option>    
                                <option v-for="template in templates"
                                        :value="template.Code">
                                    {{ template.Subject }}
                                </option>
                            </select>
                            <label>Set Email Template</label>
                        </div>  
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-primary"
                                @click="finishImportCsv">
                            Import
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <h6>Invites JSON</h6>
        <div v-for="invite in invites">
            {{ invite }}
            <hr>
        </div>

        <h6>Custom Columns JSON</h6>
        {{ customFields }}
    </div>
    <loading v-else></loading>
</template>
<script>
import Vue from 'vue'

import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import { getPollAppUrl } from './environment.hci';
import ExcelJS from 'exceljs'
import PollInviteList from './poll.invite.list.vue'
import { Modal } from 'bootstrap'
import * as Papa from 'papaparse'

export default {
    props: ['pollCode','showCode','show', 'poll'],
    mixins: [
        Token,
        Common
    ],

    computed: {
        totalRedemptions() {
            return this.invites.filter(x => x.RedeemedDateTime).length;
        }
    },

    data() {
        return {
            invites: [],
            templates: [],
            showCustomFields: false,
            editCustomFieldMapping: false,
            customFields: [],
            csvImportModal: null,

            columnsToMap: [],
            toImport: [],
            createCustomFields: false,
            templateForEmail: '',
        }
    },

    methods: {
        onSelectGlobalTemplateChange(e) {
            console.log(e.target.value);

            this.invites.forEach(i => {
                i.EmailTemplateCode = e.target.value;
                i._Modified = true;
            });
        },

        async deleteInvite(invite) {
            if(invite._IsNew) {
                let index = this.invites.indexOf(invite);
                this.invites.splice(index, 0);
                return;
            }

            if(!confirm("Are you sure you want to delete this invite?")) {
                return;
            }

            this.submitting = true;
            await this.tryDelete(`/api/poll/${this.poll.ShowCode}/${this.poll.Code}/invites/${invite.InviteCode}`);

            await this.build();
        },

        getPollLink(invite) {
            return `${getPollAppUrl()}/#/pp/${this.poll.ShowCode}/${this.poll.Code}?ic=${invite.InviteCode}`;            
        },

        uploadCsv() {
            this.csvImportModal = new Modal(this.$refs.flashCsvDialog);

            this.$refs.csvFile.click();
        },

        fileChange(fieldName, fileList) {
            this.columnsToMap = [];
            this.createCustomFields = false;
            let that = this;

            if(fileList && fileList.length > 0) {
                Papa.parse(fileList[0], {
                    header: true,
                    skipEmptyLines: true,
                    complete: function(results, file) {

                        that.toImport = results.data;
                        for(var i = 0; i < results.meta.fields.length; i++) {
                            that.columnsToMap.push({
                                ColumnName: results.meta.fields[i],
                                Destination: null
                            });
                        }

                        that.csvImportModal.show();
                    }
                });                
            }
        },

        finishImportCsv() {

            for(var i = 0; i < this.toImport.length; i++) {
                let row = this.toImport[i];

                let newRow = this.createBlankInvite();
                newRow.EmailTemplateCode = this.templateForEmail;
                this.invites.splice(0, 0, newRow);

                for(var fi = 0; fi < this.columnsToMap.length; fi++) {
                    let field = this.columnsToMap[fi];

                    let value = row[field.ColumnName];
                    value = value || "";

                    console.log('Row ', i, ", FI: ", fi, ", data: ", value);



                    if(!field.Destination) {
                        continue;
                    }

                    if(field.Destination == 'CUSTOM_NEW') {

                        if(!this.customFields.find(x => x.Name == field.ColumnName)) {
                            this.addCustomField(field.ColumnName);
                            field.Destination = `CUSTOM_${field.ColumnName}`;
                        } 
                    }

                    if(field.Destination.indexOf("CUSTOM_") == -1) {

                        newRow[field.Destination] = value;
                        
                    } else {

                        let newRowCustomField = newRow.Fields.find(x => x.Name == field.ColumnName);

                        if(newRowCustomField) {

                            newRowCustomField.Value = value;
                        }

                    }
                    
                }
            }

            this.csvImportModal.hide();
        },            


        async sendEmailToInvite(invite) {
            this.submitting = true;
            await this.tryPost('/api/poll/invitesendemail', JSON.stringify([invite]), 'application/json');
            await this.build();
        },

        async sendAllInvites() {
            this.submitting = true;

            let invites = this.invites.filter(x => x.EmailTemplateCode);
            await this.tryPost('/api/poll/invitesendemail', JSON.stringify(invites), 'application/json');

            await this.build();
        },

        async sendToUnsent() {
            this.submitting = true;

            let invites = this.invites.filter(x => x.EmailTemplateCode && !x.LastEmailDateTime);
            console.log(invites);
            this.submitting = false;

            await this.tryPost('/api/poll/invitesendemail', JSON.stringify(invites), 'application/json');

            await this.build();
        },


        async build() {
            this.submitting = true;
            this.customFields = [];
            this.invites = [];
            this.templates = [];

            let r = await this.tryGet('/api/communications/templates/' + this.show.Code);
            this.templates = r.Result;

            let invites = await this.tryGet(`/api/poll/${this.showCode}/${this.pollCode}/invites`);
            this.invites = invites.Result;

            this.updateCustomFields();

            this.submitting = false;
        },

        updateCustomFields() {
            this.invites.forEach(i => {
                let fields = i.Fields;

                if(!fields || fields.length == 0) {
                    return;
                }

                
                for(const f of fields) {
                    if(this.customFields.find(x => x.Name == f.Name)) {
                        continue;
                    }

                    this.customFields.push({
                        Name: f.Name,
                        MappedToQuestion: f.MappedToQuestion
                    });
                }

                /*
        public string Name { get; set; }
        public string MappedToQuestion { get; set; }
        public string Value { get; set; }
                */
            });
        },

        addCustomField() {
            this.customFields.push({
                Name: '',
                MappedToQuestion: '',
            });

            this.invites.forEach(x => x.Fields.push({Name: '', MappedToQuestion: '', Value: ''}));

            this.editCustomFieldMapping = true;
        },

        addCustomField(name) {
            this.customFields.push({
                Name: name,
                MappedToQuestion: '',
            });

            this.invites.forEach(x => {
                x.Fields.push({Name: name, MappedToQuestion: '', Value: ''});
                x._Modified = true;
            });
        },

        removeCustomField(index) {
            this.customFields.splice(index, 1);

            this.invites.forEach(x => {
                x._Modified = true;
                x.Fields.splice(index, 1);
            });            
        },

        updateCustomFieldDetails(ix) {
            let field = this.customFields[ix];
            console.log(field, 'Updating...');
            
            for(let i = 0; i < this.invites.length; i++) {

                let toUpdate = this.invites[i];

                let foundField = toUpdate.Fields[ix];
                
                Vue.set(foundField, 'Name', field.Name || '');
                Vue.set(foundField, 'MappedToQuestion', field.MappedToQuestion || '');
                toUpdate._Modified = true;
                console.log(toUpdate);
            }
            
        },

        triggerModified(item) {
            Vue.set(item, '_Modified', true);
            console.log("Modified");
        },

        async saveInvites() {
            let toSave = this.invites.filter(x => x._Modified);

            if(!toSave) {
                return;
            }

            this.submitting = true;
            await this.tryPost('/api/poll/invites', JSON.stringify(toSave), 'application/json');

            await this.build();
        },

        emitCancel() {
            this.$emit('cancel');
        },

        createBlankInvite() {

            let fields = [];

            this.customFields.forEach(x => {
                fields.push({
                    Name: x.Name,
                    MappedToQuestion: x.MappedToQuestion,
                    Value: ''
                });
            });

            return {
                PollCode: this.pollCode,
                ShowCode: this.showCode,
                InviteCode: '',
                RecipientName: '',
                ToEmailAddress: '',
                CcEmailAddresses: '',
                EmailTemplateCode: '',
                LastEmailDateTime: null,
                RedeemedDateTime: null,
                LastSubmitDateTime: null,
                Fields: fields,
                _IsNew: true,
                _Modified: true,
            };
        },

        addInvite() {
            let blankInvite = this.createBlankInvite();

            this.invites.splice(0, 0, blankInvite);
        }
    },

    mounted() {
        this.build();
    }
}
</script>