<template>
    <div>
        <div class="row">
            <div class="col align-items-center d-flex">
                <h5>Tasks</h5>
            </div>
            <div class="col-auto">
                <button type="button" 
                        class="btn btn-primary"
                        @click="addTask">
                    Add Task
                </button>

            </div>
        </div>

        <div class="border m-2 p-2" v-if="selectedTask">
            <h5>Edit Task</h5>
            <div class="d-flex">
                <input :disabled="submitting" 
                    :placeholder="'Title'"
                    class="form-control"
                    v-model="selectedTask.Title" />
                <button type="button" 
                        @click="saveSelectedTaskAsync"
                        class="btn btn-primary">
                    <i class="bi bi-save pe-1"></i>
                </button>
                <button type="button" 
                        @click="selectedTask = null"
                        class="btn btn-warning">
                    <i class="bi bi-x pe-1"></i>
                </button>
                <button type="button" 
                        v-if="selectedTask.Code"
                        @click="deleteTaskAsync()"
                        class="btn btn-danger">
                    <i class="bi bi-trash pe-1"></i>
                </button>
            </div>
            <div class="form-check form-check-inline mt-2 mb-2">
                <label class="form-check-label">
                    <input type="checkbox"
                            class="form-check-input"
                            v-model="selectedTask.Completed">
                    Completed
                </label>
            </div>
            <h6 class="mt-2">Due Date</h6>
            <ctk-date-time-picker v-model="selectedTask.DueDateTime"
                                format="YYYY-MM-DDTHH:mmZ"
                                minuteInterval="5"
                                :disabled="submitting"></ctk-date-time-picker>
            <textarea class="form-control mt-1"
                        placeholder="Description"
                        v-model="selectedTask.Notes">
            </textarea>
            <h6 class="mt-2">Assigned To</h6>
            <input class="form-control"
                    placeholder="Assigned To"
                    v-model="selectedTask.AssignedTo">
            <h6 class="mt-2" v-if="!showCode">Show</h6>
            <select class="form-select"
                    v-if="!showCode"
                    v-model="selectedTask.ShowCode">
                <option v-for="show in showList" 
                        :key="show.Code"
                        v-bind:value="show.Code">
                    {{show.Name}} | {{show.StartDateTime | moment('MMMM Do YYYY') }}
                </option>
            </select>
        </div>

        <div class="border m-2 p-2 text-danger" 
                v-for="task in sortedOverdueTasks"
                v-bind:key="task.Code">
            <div class="row">
                <div class="col-auto d-flex flex-column align-items-center justify-content-center">
                    <button type="button" 
                            @click="selectedTask = task"
                            class="btn btn-primary">
                        <i class="bi bi-pencil-square pe-1"></i>
                    </button>
                    <button type="button" 
                            @click="goToRelatedEntity(task)"
                            v-if="task.EntityTypeCode && task.EntityCode"
                            class="btn btn-primary mt-1">
                        🔗
                    </button>
                    <button type="button"
                            @click="changeTaskCompleteStatus(task)"
                            class="btn btn-primary mt-1">
                        <i class="pe-1"
                            :class=" task.Completed ? 'bi-check-square' : 'bi-square'"></i>
                    </button>
                </div>
                <div class="col text-truncate">
                    <h6>
                        {{task.Title}}
                    </h6>
                    <h6 v-if="!showCode">
                        {{getShowName(task.ShowCode)}}
                    </h6>
                    <h6>
                        <span class="fw-bold">Due {{task.DueDateTime | moment('MM-DD-YYYY')}}</span>
                        | <span v-if="task.AssignedTo">Assigned to {{task.AssignedTo}}</span>
                    </h6>
                    <h6>
                        <span>Updated {{task.LastUpdatedDateTime | moment('MM-DD-YYYY HH:mm')}}</span>
                    </h6>
                </div>
            </div>
        </div>

        <div class="border m-2 p-2" 
                v-for="task in sortedDueTasks"
                v-bind:key="task.Code">
            <div class="row">
                <div class="col-auto d-flex flex-column align-items-center justify-content-center">
                    <button type="button" 
                            @click="selectedTask = task"
                            class="btn btn-primary">
                        <i class="bi bi-pencil-square pe-1"></i>
                    </button>
                    <button type="button" 
                            @click="goToRelatedEntity(task)"
                            v-if="task.EntityTypeCode && task.EntityCode"
                            class="btn btn-primary mt-1">
                        🔗
                    </button>
                    <button type="button"
                            @click="changeTaskCompleteStatus(task)"
                            class="btn btn-primary mt-1">
                        <i class="pe-1"
                            :class=" task.Completed ? 'bi-check-square' : 'bi-square'"></i>
                    </button>
                </div>
                <div class="col text-truncate">
                    <h6>
                        {{task.Title}}
                    </h6>
                    <h6 v-if="!showCode">
                        {{getShowName(task.ShowCode)}}
                    </h6>
                    <h6>
                        <span class="fw-bold">Due {{task.DueDateTime | moment('MM-DD-YYYY')}}</span>
                        | <span v-if="task.AssignedTo">Assigned to {{task.AssignedTo}}</span>
                    </h6>
                    <h6>
                        <span>Updated {{task.LastUpdatedDateTime | moment('MM-DD-YYYY HH:mm')}}</span>
                    </h6>
                </div>
            </div>
        </div>
        <template v-if="!hideCompleted">
            <div class="border m-2 p-2 text-black-50" 
                    v-for="task in sortedCompletedTasks"
                    v-bind:key="task.Code">
                <div class="row">
                    <div class="col-auto d-flex flex-column align-items-center justify-content-center">
                        <button type="button" 
                                @click="selectedTask = task"
                                class="btn btn-primary">
                            <i class="bi bi-pencil-square pe-1"></i>
                        </button>
                        <button type="button" 
                                @click="goToRelatedEntity(task)"
                                v-if="task.EntityTypeCode && task.EntityCode"
                                class="btn btn-primary mt-1">
                            🔗
                        </button>
                        <button type="button"
                            @click="changeTaskCompleteStatus(task)"
                            class="btn btn-primary mt-1">
                            <i class="pe-1"
                                :class=" task.Completed ? 'bi-check-square' : 'bi-square'"></i>
                        </button>
                    </div>
                    <div class="col text-truncate">
                        <h6>
                            {{task.Title}}
                        </h6>
                        <h6 v-if="!showCode">
                            {{getShowName(task.ShowCode)}}
                        </h6>
                        <h6>
                            <span class="fw-bold">Due {{task.DueDateTime | moment('MM-DD-YYYY')}}</span>
                            | <span v-if="task.AssignedTo">Assigned to {{task.AssignedTo}}</span>
                        </h6>
                        <h6>
                            <span>Updated {{task.LastUpdatedDateTime | moment('MM-DD-YYYY HH:mm')}}</span>
                        </h6>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import moment from 'moment'

export default {
    mixins: [
        Token,
        Common
    ],

    props: [
        'showCode',
        'showDetails', 
        'taskCode', 
        'showList',
        'entityTypeCode',
        'entityCode',
        'hideCompleted'],

    computed: {
        sortedOverdueTasks() {
            return this.tasks.filter(x => !x.Completed && moment(x.DueDateTime) - moment() < 0).sort((a, b) => {
                return moment(b.DueDateTime) - moment(a.DueDateTime)
            });
        },


        sortedDueTasks() {
            return this.tasks.filter(x => !x.Completed && moment(x.DueDateTime) - moment() >= 0).sort((a, b) => {
                return moment(b.DueDateTime) - moment(a.DueDateTime)
            });
        },

        sortedCompletedTasks() {
            return this.tasks.filter(x => x.Completed).sort((a, b) => {
                return moment(b.DueDateTime) - moment(a.DueDateTime)
            });
        },
    },

    watch: {
        taskCode() {
            this.updateSelectedTask();
        },

        entityTypeCode() {
            this.build();
        },

        entityCode() {
            this.build();
        },

        showDetails() {
            this.build();
        }        
    },

    data() {
        return {
            tasks: [],
            selectedTask: null,            
        }
    },

    methods: {

        changeTaskCompleteStatus(task){
            task.Completed = !task.Completed;
            this.selectedTask = task;
            this.saveSelectedTaskAsync();
        },
        goToRelatedEntity(task) {
            if(task.EntityTypeCode == 'POLLRESPONSE') {
                let components = task.EntityCode.split("_");
                let pollCode = components[0];
                let responseCode = components[1];

                this.$router.push({
                    path: `/shows/${task.ShowCode}/topage/${pollCode}/${responseCode}`
                });
            }
        },

        getShowName(showCode) {
            let show = this.showList.find(x=>x.Code == showCode);

            return show ? show.Name : 'Unknown';
        },

        addTask() {
            this.selectedTask = {
                Title: '',
                Notes: '',
                DueDateTime: moment(new Date()).utc(false).toISOString(),
                LastUpdatedDateTime: moment(new Date()).utc(false).toISOString(),
                AssignedTo: '',
                Completed: false,
                ShowCode: this.showCode || null,
                Code: null,
                EntityTypeCode: this.entityTypeCode || null,
                EntityCode: this.entityCode || null,
            };
        },

        async deleteTaskAsync() {
            let result = confirm("Are you sure you want to delete this task? This cannot be undone.");

            if(!result) {
                return;
            }

            this.submitting = true;
            
            try {
                await this.tryPost(`/api/admin/task/delete`, JSON.stringify(this.selectedTask), 'application/json');

                this.tasks.splice(this.tasks.indexOf(this.selectedTask), 1);
            } catch(ex) {

            }

            this.selectedTask = null;
            this.submitting = false;
            this.$emit('loaded-tasks', this.tasks);
        },

        async saveSelectedTaskAsync() {
            if(this.selectedTask.ShowCode == null) {
                alert("No show code selected. Aborting save.");
                return;
            }

            this.submitting = true;

            try {
                let r = await this.tryPost('/api/admin/tasks', JSON.stringify(this.selectedTask), 'application/json');

                if(!r.Result) {
                    alert("Could not save.");
                    throw "Nope.";
                }

                let existingItem = this.tasks.find(x => x.Code == r.Result.Code);

                if(!existingItem) {
                    this.tasks.push(r.Result);
                } else {
                    this.tasks.splice(this.tasks.indexOf(existingItem), 1, r.Result);
                }

            } catch(ex) {
                console.error(ex);
            }

            this.selectedTask = null;
            this.submitting = false;
            this.$emit('loaded-tasks', this.tasks);
        },

        updateSelectedTask() {
            this.selectedTask = this.tasks.find(x => x.Code == this.taskCode);
        },


        async build() {
            this.submitting = true;

            try {
                let routeUrl = '/api/admin/tasks';

                if(this.showCode) {
                    routeUrl = `${routeUrl}/${this.showCode}`;
                }

                if(this.entityTypeCode) {
                    routeUrl = `${routeUrl}/${this.entityTypeCode}`;
                }

                if(this.entityCode) {
                    routeUrl = `${routeUrl}/${this.entityCode}`;
                }

                let result = await this.tryGet(routeUrl);

                this.tasks = result.Result;

            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
            this.$emit('loaded-tasks', this.tasks);
        },

    },

    created() {
        this.build();
    }
}
</script>