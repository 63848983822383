<template>
    <loading v-if="submitting">
    </loading>
    <div class="container-fluid scrolling-columns" v-else>
        <button class="btn btn-primary" @click="onSave">
            Save
        </button>
        <template v-for="(category, categoryCode) in permissionGroups">
            <h4 class="sticky-top p-3 m-0 bg-body-tertiary border border-bottom-0">
                <div class="btn-group">
                    <button class="btn btn-primary" type="button" @click="onSelectAll(categoryCode)">Can Record</button>
                    <button class="btn btn-primary" type="button" @click="onSelectAllConvert(categoryCode)">Auto-Convert</button>
                    <button class="btn btn-primary" type="button" @click="onSelectAllUpload(categoryCode)">Can Upload</button>
                </div>
                {{getCategoryName(categoryCode)}} ({{category.length}})
            </h4>
            <div class="list-group">
                <div v-for="item in category"
                        class="list-group-item list-group-item-action"
                        :key="item.RoomCode">
                    <div class="row">
                        <div class="col-auto">
                            {{item.RoomName}}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col">
                            <div class="form-check">
                                <label 
                                    class="form-check-label">
                                    <input v-model="item.CanRecord"
                                        class="form-check-input" 
                                        type="checkbox">Can Record
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <label 
                                    class="form-check-label">
                                    <input v-model="item.AutoConvertRecordings"
                                        class="form-check-input" 
                                        type="checkbox">Auto-Convert Recordings to HLS
                                </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="form-check">
                                <label 
                                    class="form-check-label">
                                    <input v-model="item.CanUpload"
                                        class="form-check-input" 
                                        type="checkbox">Can Upload
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'

export default {
    
    props: ['showDetails', 
            'showCode'],

    mixins: [
        Token,
        Common
    ],

    methods: {  

        onSelectAll(categoryCode){
            let elements = this.permissionGroups[categoryCode];

            elements.forEach(e => {
                e.CanRecord = !e.CanRecord;
            });
        },

        onSelectAllUpload(categoryCode) {
            let elements = this.permissionGroups[categoryCode];

            elements.forEach(e => {
                e.CanUpload = !e.CanUpload;
            });
        },

        onSelectAllConvert(categoryCode) {
            let elements = this.permissionGroups[categoryCode];

            elements.forEach(e => {
                e.AutoConvertRecordings = !e.AutoConvertRecordings;
            });
        },

        getCategoryName(code) {
            switch(code){
                case 'LiveQa': return "Live Q&A Room";
                case 'MeetingRoom': return "Meeting Room";
                case 'Chat': return "Chat";
                default: return code;
            }
        },

        async onSave() {
            try{
                let toPost = [];

                let keys = Object.keys(this.permissionGroups);

                keys.forEach(k => {
                    toPost.push(...this.permissionGroups[k]);
                });

                await this.tryPost(`/api/recording/permissions`, 
                                    JSON.stringify(toPost), 
                                    'application/json');
            }
            catch(ex){
                console.log(ex);
            }
        },

        async build() {
            this.submitting = true;
                       
            try {
                let result = await this.tryGet(`/api/recording/permissions/${this.showCode}`);

                let typeCodes = [...new Set(result.Result.map(x => x.RoomTypeCode))]

                typeCodes.forEach(element => {
                    Vue.set(this.permissionGroups,
                            element,
                            [...result.Result.filter(x => x.RoomTypeCode == element)]);
                });
               

            } catch(ex) {
                console.log(ex);
                alert("Could not load pages. Try again later.");
            }
            
            this.submitting = false;
        },
    },

    data() {
        return {
            permissionGroups: {},
        }
    },

    mounted: function() {
        this.build();
    },
}
</script>