<template>
    <loading v-if="submitting">

    </loading>
    <div class="row" v-else>
        <div class="col">
            <div class="row">
                <div class="col-md-10">
                    <input class="mb-1" placeholder="Search" type="search" v-model="textFilter">
                </div>
                <div class="col-auto">
                    {{filteredAttendees.length}} / {{attendees.length}}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <table class="table table-striped">
                        <thead class="table-light">
                            <tr>
                                <th><input type="checkbox" @change="selectFiltered()"></th>
                                <th>Name</th>
                                <th>Code</th>
                                <th>Role</th>
                                <th style="width: 33%">
                                    <span @click.stop="selectAllCategories()">Category</span><br/>
                                    <span class="badge"
                                          :class="[categoryFilter.includes(category.Code) ? 'bg-primary' : 'bg-secondary']" 
                                          v-for="(category, idx) in showDetails.AttendeeCategories" 
                                          @click="toggleCategoryFilter(category.Code)">
                                        {{category.Name}}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="attendee in filteredAttendees"
                                :key="attendee.Code"
                                @click="attendee.Selected = !attendee.Selected"
                                :class="[{'table-primary': attendee.Selected}]">
                                <td>
                                    <input type="checkbox" v-model="attendee.Selected">
                                </td>
                                <td>
                                    {{attendee.Details.DisplayName}}
                                </td>
                                <td>
                                    {{attendee.Code}}
                                </td>
                                <td>
                                    {{attendee.Details.RoleCode}}
                                </td>
                                <td>
                                    <span v-for="(category, idx) in attendee.Details.Categories"
                                       class="badge"
                                       :class="[categoryFilter.includes(category.Code) ? 'bg-primary' : 'bg-secondary']" 
                                       :key="category.Code"
                                       @click.stop="toggleCategoryFilter(category.Code)">
                                       {{(showDetails.AttendeeCategories.find(x=>x.Code == category.Code) == null) ? "Deleted" : showDetails.AttendeeCategories.find(x=>x.Code == category.Code).Name}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Common from '../common'
import Token from '../authentication/token'

export default {
    mixins: [
        Common, Token
    ],

    props: ['showDetails', 'attendeesSelected'],

    data() {
        return {
            attendeeList: [],
            attendees: [],
            categoryFilter: [],
            textFilter: '',
        }
    },

    computed: {
        filteredAttendees() {

            let toReturn = this.attendees;

            if(this.textFilter && this.textFilter.length > 0) {
                let filter = this.textFilter.toLowerCase().trim();
                toReturn = toReturn.filter(x => 
                    (x.Details.DisplayName || '').toLowerCase().includes(filter)
                    || (x.Details.FirstName || '').toLowerCase().includes(filter)
                    || (x.Details.LastName || '').toLowerCase().includes(filter)
                    || x.Code.toLowerCase().includes(filter));
            }

            if(this.categoryFilter.length > 0) {
                toReturn = toReturn.filter(x => x.CategoryLookup.some(c => this.categoryFilter.includes(c)));
            }

            return toReturn;
        }
    },

    methods: {
        selectAllCategories() {
            this.categoryFilter.splice(0, this.categoryFilter.length);
            this.categoryFilter.push(...this.showDetails.AttendeeCategories.map(x=>x.Code));
        },

        selectFiltered() {
            this.filteredAttendees.forEach(element => {
                element.Selected = !element.Selected;
            });
        },
        
        toggleCategoryFilter(categoryCode) {
            if(this.categoryFilter.includes(categoryCode)) {
                this.categoryFilter.splice(this.categoryFilter.indexOf(categoryCode), 1);
            } else {
                this.categoryFilter.push(categoryCode);
            }
        },

        getSelectedAttendees() {
            return this.attendees.filter(x=>x.Selected);
        },

        async build() {
            this.submitting = true;

            try {
                let r = await this.tryGet(`/api/show/${this.showDetails.Code}/attendees`);
                this.attendeeList = r.Result;

                this.attendees.push(...this.attendeeList.map(a => {
                    return {
                        Details: a,
                        Code: a.Code,
                        Selected: false,
                        CategoryLookup: a.Categories.map(x=>x.Code)
                    }
                }));

                if(this.attendeesSelected && this.attendeesSelected.length > 0){
                    for(var i = 0; i < this.attendeesSelected.length; i++){
                        let index = this.attendees.findIndex(x => x.Code == this.attendeesSelected[i]);
                        this.attendees[index].Selected = true;
                    }
                }

                this.categoryFilter.push(...this.showDetails.AttendeeCategories.map(x=>x.Code));


            } catch(ex) {
                console.error(ex);
            }

            this.submitting = false;
        }
    },

    mounted() {
        this.build();
    }
}
</script>