<template>
    <div class="container-fluid scrolling-columns">
        <div class="row">
            <div class="col-6">
                <vue-cal :time-from="10 * 60"
                         :disable-views="['years', 'day']"
                         events-count-on-year-view
                         active-view="month"
                         :time="false"
                         :start-week-on-sunday="true"
                         :events="events">
                </vue-cal>
            </div>
            <div class="col-6">
                <div class="d-flex mb-2 align-items-center justify-content-center">
                    <h5 class="me-2">Calendar Mode</h5>
                    <div>
                        <button type="button"
                                class="btn btn-primary"
                                @click="displayShows">
                            Shows
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="displayTasks">
                            Tasks
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                @click="displayAll">
                            Shows &amp; Tasks
                        </button>
                    </div>
                </div>
                <task-editor :showCode="this.showCode"
                             :showDetails="showDetails"
                             :showList="shows"
                             :taskCode="taskCode"
                             v-on:loaded-tasks="onLoadedTasks">

                </task-editor>

            </div>            
        </div>
    </div>
</template>
<script>
import Vue from 'vue'

import Token from './authentication/token'
import Common from './common'
import moment from 'moment'

let localBus = new Vue();

export default {
    props: ['showDetails', 'showCode', 'taskCode'],

    mixins: [
        Token,
        Common
    ],


    methods: {
        onLoadedTasks(tasks) {
            this.tasks = tasks;
        },

        displayShows() {
            this.events = [];
            
            this.events.push(...this.shows.map(s => {
                return {
                    show: s,
                    start: moment(s.StartDateTime).format('YYYY-MM-DD'),
                    end: moment(s.StartDateTime).format('YYYY-MM-DD'),
                    title: s.Name
                };
            }));

        },

        displayTasks() {
            this.events = [];

            this.events.push(...this.tasks.filter(x => !x.Completed).map(s => {
                return {
                    task: s,
                    start: moment(s.DueDateTime).format('YYYY-MM-DD'),
                    end: moment(s.DueDateTime).format('YYYY-MM-DD'),
                    title: s.Title
                };
            }));

        },

        displayAll() {
            this.events = [];
            
            this.events.push(...this.shows.map(s => {
                return {
                    show: s,
                    start: moment(s.StartDateTime).format('YYYY-MM-DD'),
                    end: moment(s.StartDateTime).format('YYYY-MM-DD'),
                    title: s.Name
                };
            }));

            this.events.push(...this.tasks.filter(x => !x.Completed).map(s => {
                return {
                    task: s,
                    start: moment(s.DueDateTime).format('YYYY-MM-DD'),
                    end: moment(s.DueDateTime).format('YYYY-MM-DD'),
                    title: s.Title
                };
            }));

        },

        async loadShowListAsync() {
            try{
                let r = await this.tryGet('/api/shows?mode=list');
                    
                this.shows = r.Result.sort((a, b) => {
                    return moment(b.StartDateTime) - moment(a.StartDateTime)
                });
            }
            catch(ex){
                console.log(ex);
            }
        },

        async build() {
            if(!this.showCode) {            
                await this.loadShowListAsync();
            } else {
                this.shows = [];
                this.shows.push(this.showDetails);
            }

            this.displayShows();
        }
    },

    data() {
        return {
            events: [],

            submitting: false,

            calendar: [],
            tasks: [],
            shows: [],

            selectedTask: null,
                       
            selectedDate: null,

            splitDays: [],
        }
    },

    created: function() {
        this.build();     

    },
}
</script>