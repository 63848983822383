<template>
    <loading v-if="submitting"></loading>
    <div class="scrolling-columns calendar-page" v-else>
        <div class="row">
            <div class="calendar-column" 
                 :class="{ 'col-12': !editSession && !editCategory && !showCategoryList, 'editor-visible col': editSession || editCategory || showCategoryList }">
                <div class="calendar-header">
                    <button type="button" class="btn btn-primary" @click="$refs.calendar.previous()">
                        &lt;
                    </button>
                    <button type="button" class="btn btn-primary" @click="showCategoryList = !showCategoryList">
                        Edit Categories
                    </button>
                    <router-link class="btn btn-primary" :to="{ name: 'ShowRundown' }">
                        View Rundown
                    </router-link>
                    <button type="button" class="btn btn-primary" @click="$refs.calendar.next()">
                        &gt;
                    </button>
                </div>

                <vue-cal :events="sessionCalendar"
                        :time-cell-height="150"
                        :selected-date.sync="selectedDate"
                        id="calendar"
                        ref="calendar"
                        :active-view="'day'"
                        :disable-views="['years', 'year', 'month']"
                        :editable-events="{ title: false, drag: true, resize: true, delete: false, create: true }"
                        :snap-to-time="5"
                        :on-event-click="onClickEvent"
                        :drag-to-create-threshold="0"
                        :split-days="splitDays"
                        :sticky-split-labels="true"
                        :watch-real-time="true"
                        @event-drop="onEventDrop"
                        @ready="scrollToCurrentTime"
                        @view-change="onViewChange"
                        @event-duration-change="onEventDrop"
                        @event-drag-create="onEventCreate">

                </vue-cal>
            </div>
            <div class="col-auto" v-if="editSession || editCategory || showCategoryList">
                <div class="category-list" v-if="showCategoryList">

                    <table class="table table-hover table-striped table-sm">
                        <thead>
                            <tr>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Code
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="category in show.SessionCategories"
                                :key="category.Code"
                                class="pe-action"
                                :class="[editCategory && editCategory == category ? 'table-primary' : '']"
                                @click="onClickCategory(category)">
                                <td>
                                    {{ category.Name }}
                                </td>
                                <td>
                                    {{ category.Code }}
                                </td>
                            </tr>
                            <tr>
                                <td colspan="10">
                                    <button class="btn btn-primary btn-sm"
                                            @click="addCategory">
                                        Add...    
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>    
                </div>
                <session-editor :session="editSession.session"
                                :show="show"
                                v-if="editSession"
                                v-on:deleted="onDeleted"
                                v-on:cancel="onEditCancel"
                                v-on:saved="onEditSave">
                </session-editor>
                <session-category-editor :show="show"
                                    :sessionCategory="editCategory"
                                    :isNewCategory="editCategory.Id == '0'"
                                    @saved="onEditCategorySave"
                                    @cancel="onEditCategoryCancel"
                                    v-if="editCategory">
                </session-category-editor>               
            </div>

        </div>

    </div>
</template>
<style>
.vuecal {
    height: calc(100% - 52px) !important; 
}
/* .calendar-header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.calendar-header > button {
    margin-right: 0.5em;
}

.category-list > button {
    margin: 0.2em;
}

.calendar-editor {
    display: flex;
}

.calendar-column {
    height: calc(100vh - 330px);
    width: 100%;
}

.editor-visible {
    width: 40% !important;
}

.calendar-editor > div:first-child {
    height: 100%;
}
*/

.vuecal__event {
    color: white !important;
    background-color: rgba(0,129,255,0.8) !important;
}

.vuecal__cell-split {
    border-left: 1px solid lightblue;
}

</style>
<script>
import Vue from 'vue'

import Token from './authentication/token'
import Session from '../models/Session'
import SessionContactInfo from '../models/VendorAdditionalInfo'
import AgendaItemCategory from '../models/AgendaItemCategory'
import Common from './common'

let localBus = new Vue();

export default {
    props: ['showDetails', 'showCode'],

    mixins: [
        Token,
        Common
    ],

    watch: {
        showDetails(to, from) {
            this.show = to;
        }
    },

    methods: {

        async onEventDrop(e, ee) {
            if(e.newSplit) {
                e.event.session.Category.Code = e.newSplit;
            }

            e.event.session.SessionStartDateTime = e.event.start;
            e.event.session.SessionEndDateTime = e.event.end;

            try {
                await this.tryPost('/api/session', JSON.stringify(e.event.session), 'application/json');
            } catch(ex) {
                alert("We could not save your changes. The page will now reload.");
            }

            this.reloadSessions();
        },

        addCategory() {
            this.editCategory = null;
            
            Vue.nextTick(() => {
                let newCategory = new AgendaItemCategory();
                newCategory.Id = '0';
                newCategory.Code = this.createUniqueCode();

                this.editCategory = newCategory;
            });
        },

        scrollToCurrentTime() {
            const calendar = document.querySelector("#calendar .vuecal__bg");
            const hours = new Date().getHours() + new Date().getMinutes() / 60
            calendar.scrollTo({ top: 7 * 100 })
        },

        onViewChange(e) {
            this.splitDays = [];
            if(e.view == 'day') {

                for(let i = 0; i < this.show.SessionCategories.length; i++) {
                    this.splitDays.push({
                        id: this.show.SessionCategories[i].Code,
                        label: this.show.SessionCategories[i].Name,
                        category: this.show.SessionCategories[i]
                    });
                }
            }
        },

        onClickEvent(e) {
            this.editSession = null;
            Vue.nextTick(() => {
                this.editSession = e;
            });
        },

        onEventCreate(e) {
            let newSession = new Session()
            newSession.SessionStartDateTime = e.start;
            newSession.SessionEndDateTime = e.end;

            if(e.split) {
                newSession.Category = {
                    Code: e.split
                };
            }

            newSession.Show = {
                Code: this.show.Code
            };
            newSession.ContactInfo = new SessionContactInfo();
            e.session = newSession;
            this.editSession = e;
        },

        onClickCategory(e) {
            this.editCategory = null;
            Vue.nextTick(() => {
                this.editCategory = e;
            });
        },

        onEditCategoryCancel() {
            this.editCategory = null;
        },

        onEditCategorySave() {
            this.editCategory = null;
            this.showCategoryList = false;
            this.bus.$emit('Do-Show-Reload');            
        },

        onEditCancel() {
            this.editSession = null;
            this.reloadSessions();
        },

        onDeleted(sessionFromService) {
            this.editSession = null;
            this.reloadSessions();
        },

        onEditSave(show, sessionFromService) {
            this.editSession = null;
            this.reloadSessions();    
        },

        async reloadSessions() {
            try {
                let result = await this.tryGet(`/api/show/${this.showCode}/sessions`);
                this.sessions = result.Result;
            } catch {
                alert("Could not load the calendar. Please try again later.");
            }

            this.sessionCalendar = [];

            for(const [k, sessions] of Object.entries(this.sessions))
            {
                this.sessionCalendar.push(...sessions.map(s => {
                    let toReturn = {
                        session: s,
                        start: new Date(s.SessionStartDateTime),
                        end: new Date(s.SessionEndDateTime),
                        title: s.Name,
                        class: `S-${k}`,
                        split: s.Category.Code
                    };
                    this.allSessions.push(...sessions);
                    return toReturn;
                }))
            }
            
            //this.editSession = this.sessionCalendar[0];
        },

        async build() {
            this.submitting = true;

            await this.reloadSessions();

            this.submitting = false;
        }
    },

    data() {
        return {
            sessionCalendar: [],
            allSessions: [],
            showCategoryList: false,

            editSession: null,
            editCategory: null,
            
            show: null,
            
            selectedDate: null,

            splitDays: [],
            sessions: [],
        }
    },

    created: function() {
        this.show = this.showDetails;
        this.selectedDate = new Date(this.show.StartDateTime);

        localBus.$on("editCategory", (c) => {
            if(this.displayMode != 'Categories') {
                return;
            }

            if(this.editCategory != null) {
                this.editCategory = null;
            }

            this.$nextTick(() => {
                this.editCategory = this.show.SessionCategories.find(x=>x.Code == c);
            });          

        });

        localBus.$on('newCategory', () => {
            let newCategory = new AgendaItemCategory();
            newCategory.Id = '0';
            newCategory.Code = this.createUniqueCode();

            this.editCategory = newCategory;
        });

        this.onViewChange({
            view: 'day'
        });
        this.build();     

    },
}
</script>