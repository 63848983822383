<template>
    <loading v-if="submitting">
    </loading>
    <div class="container-fluid" v-else>
        <div class="row" v-if="validateErrors && validateErrors.length > 0">
            <div class="mt-1 alert alert-danger text-start" role="alert">
                    Oops! It looks like something happened. Please double-check and try again.
                    <ul>
                        <li v-for="error in validateErrors">
                            {{ error }}
                        </li>
                    </ul>
            </div>
        </div>
        <AttendeeListSelector :showSidebar="false"
                              :showSignInLink="false"
                              :showDetails="showDetails"
                              :attendees="sortedAttendees"
                              :showCode="showCode"
                              :selectedCodes="selectedAttendees"
                              @select-attendee="selectAttendee"
                              @attendee-edited="onAttendeeEdited"
                              v-if="!submitting"
                              :customColumns="customColumns"
                              ref="attendeeList">
            <template v-slot:mainbuttons>
                <div class="btn-group">
                    <select v-model="selectedEntity">
                        <optgroup v-for="(entities, category) in sessionsAndVendors"
                                  :label="category">
                            <option v-for="entity in entities"
                                    :value="entity">
                                {{entity.DisplayName}}
                            </option>
                        </optgroup>
                    </select>
                    <button type="button" 
                            class="btn btn-primary buttonMargin"
                            style="margin-left:0.5em;"
                            @click="syncWithContactInfo">
                        Sync with Contact Info
                    </button>
                    <button type="button" 
                            class="btn btn-primary buttonMargin"
                            data-bs-toggle="modal"
                            data-bs-target="#syncAll">
                        Sync All Contact Info
                    </button>
                    <button type="button" 
                            class="btn btn-primary buttonMargin"
                            @click="onSave">
                        Save
                    </button>
                </div>
            </template>
        </AttendeeListSelector> 
        <div class="modal fade" 
                id="syncAll"
                ref="syncAll">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body file-upload-modal-body">
                        This will run for all sessions and could take a long time are you sure?
                    </div>
                    <div class="modal-footer">
                        <button type="button" 
                                class="btn btn-secondary" 
                                data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <button type="button"
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                                @click="syncAllContactInfo">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.buttonMargin {
    margin-right: 0.5em;
}
</style>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'

import AttendeeListSelector from './attendee.list.selector.vue'

export default {
    props: ['showDetails', 'showCode'],

    components: { 'AttendeeListSelector': AttendeeListSelector },

    mixins: [
        Token,
        Common
    ],   

    watch:{
        selectedEntity(){
            if(this.selectedEntity){
                this.loadFavorites();
            }
        }
    },

    computed: {
        sortedAttendees() {
            let toReturn = this.attendees;

            toReturn = toReturn.sort((a, b) => {
                let aI = this.selectedAttendees.indexOf(a.Code);
                let bI = this.selectedAttendees.indexOf(b.Code);

                if(aI == bI) {
                    return 0;
                } else if(aI == -1) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return toReturn;
        },

        categoryFilters() {
            return this.$refs.attendeeList.categoryFilters;
        },

        filteredList() {
            return this.$refs.attendeeList.filteredList;
        }
    },

    methods: {
        async loadFavorites(){
            try{
                this.selectedAttendees = [];
                let r = await this.tryGet(`/api/favorites/${this.showCode}/update/${this.selectedEntity.EntityTypeCode}/${this.selectedEntity.Code}`);
                let batchFavorites = r.Result;

                for(var attendee of this.attendees){
                    let foundFavorite = batchFavorites.find(x => x.AttendeeCode == attendee.Code);
                    let isReadOnly = false;
                    let allowEditEntity = false;
                    let receiveAdminEmail = false;

                    if(foundFavorite){
                        if(foundFavorite.ReadOnly == true){
                            isReadOnly = true;
                        }
                        if(foundFavorite.AllowEditEntity){
                            allowEditEntity = true;
                        }
                        if(foundFavorite.ReceiveAdminEmail == true) {
                            receiveAdminEmail = true;
                        }

                        this.selectedAttendees.push(attendee.Code);
                    }
                    Vue.set(attendee,'AllowEditEntity', allowEditEntity);
                    Vue.set(attendee,'IsReadOnly', isReadOnly);
                    Vue.set(attendee,'Modified',false);
                    Vue.set(attendee,'ReceiveAdminEmail', receiveAdminEmail);                    
                }
            }
            catch(ex){

            }
        },
        async syncAllContactInfo(){
            if(!this.selectedEntity){
                return;
            }
            try{
                let entityTypeCode = this.selectedEntity.EntityTypeCode;
                let url = `/api/favorites/${this.showCode}/createfromcontactinfo/${entityTypeCode}`;

                let result = await this.tryPost(url, JSON.stringify({}), 'application/json');
            }
            catch(ex) {
                console.error(ex);
            }
        },
        async syncWithContactInfo(){
            if(!this.selectedEntity){
                return;
            }
            try{
                let entityTypeCode = this.selectedEntity.EntityTypeCode;
                let entityCode = this.selectedEntity.Code;

                let contactInfo = [];
                if(entityTypeCode == 'VENDOR'){
                    let r = await this.tryGet(`/api/vendor/${this.showCode}/${entityCode}`);
                    contactInfo = r.Result.Vendor.ContactInfo.ContactInfo;
                }
                else{
                    //assuming Session
                    let r = await this.tryGet(`/api/session/${this.showCode}/${entityCode}`);
                    contactInfo = r.Result.Session.ContactInfo.ContactInfo;
                }
                

                let url = `/api/favorites/${this.showCode}/createfromcontactinfo/${entityTypeCode}/${entityCode}/`;
                let result = await this.tryPost(url, JSON.stringify(contactInfo), 'application/json');
            }
            catch(ex) {
                console.error(ex);
            }
        },
        async getSessionsAndVendors(){
            let name = "SESSIONS;VENDORS";
            let r = await this.tryGet(`/api/resources/${this.showCode}?t=${name}`);

            try {
                r.Result.VENDORS.forEach(v => v.EntityTypeCode = "VENDOR");
            } catch {

            }

            try {
                r.Result.SESSIONS.forEach(v => v.EntityTypeCode = "SESSION");
            } catch {

            }

            this.sessionsAndVendors = r.Result;
        },
        selectAttendee(attendee) {
            let index = this.selectedAttendees.indexOf(attendee.Code);

            if(index == -1) {
                this.selectedAttendees.push(attendee.Code);
            } else {
                this.selectedAttendees.splice(index, 1);
            }

            attendee.Modified = true;
        },

        onAttendeeEdited(attendee) {
            attendee.Modified = true;            
        },

        async onSave() {
            this.submitting = true;
            try{

                let modifiedAttendees = this.attendees.filter(x => x.Modified == true);
                if(modifiedAttendees.length == 0){
                    return;
                }

                let batchAttendees = [];
                for(var attendee of modifiedAttendees){
                    batchAttendees.push({
                        'AttendeeCode': attendee.Code,
                        'EntityTypeCode': this.selectedEntity.EntityTypeCode,
                        'EntityCode': this.selectedEntity.Code,
                        'Active': this.selectedAttendees.includes(attendee.Code) ? true : false,
                        'ReadOnly': attendee.IsReadOnly,
                        'AllowEditEntity': attendee.AllowEditEntity,
                        'ReceiveAdminEmail': attendee.ReceiveAdminEmail
                    });
                }

                let url = `/api/favorites/${this.showCode}/update`
                let r = await this.tryPost(url, JSON.stringify(batchAttendees), 'application/json')

                await this.build();
            }
            catch(ex){
                console.log(ex);
            }

            this.submitting = false;
            
        },

        async build() {
            this.submitting = true;
            this.selectedEntity = null;
            this.selectedAttendees = [];
            try {
                let result = await this.tryGet(`/api/show/${this.showCode}/attendees`);
                
                result.Result.forEach(element => {
                    Vue.set(element, 'IsReadOnly', true);
                    Vue.set(element, 'Modified', false);
                    Vue.set(element, "ReceiveAdminEmail", false);
                });

                this.attendees = result.Result;
            } catch {
                alert("Could not load attendees. Try again later.");
            }

            this.submitting = false;

            Vue.nextTick(() => {
                this.$refs.attendeeList.resetFilters();
            })
        },

    },

    data() {
        return {
            selectedAttendees: [],

            validateErrors: [],
            attendees: [],

            customColumns: [
                {Type: 'Checkbox', Path: 'IsReadOnly', Header: 'Read Only'},
                {Type: 'Checkbox', Path: 'AllowEditEntity', Header: 'Allow Edit Entity'},
                {Type: 'Checkbox', Path: 'ReceiveAdminEmail', Header: 'Receive Admin Email'},
                {Type: 'Checkbox', Path: 'Modified', Header: 'Modified', ReadOnly: true},
            ],

            sessionsAndVendors: [],
            selectedEntity: null,
        }
    },

    async created(){
        await this.getSessionsAndVendors();
    },

    mounted() {
        this.build();
    }
}
</script>