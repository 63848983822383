<template>
    <div>
        <div v-if="submitting">
            <loading></loading>
        </div>
        <nav class="navbar">
            <div class="container-fluid">
                <h1 class="navbar-brand">
                    {{showDetails.Name}}
                </h1>
                <div>
                    <button class="btn btn-primary"
                            @click="printRundown">
                        Print
                    </button>
                </div>
            </div>
        </nav>
        <div ref="mainTable">
            <div class="mt-2" 
                v-for="([day,sessionDayInfo],idx) in sessions" >
                <h1 :class="[idx > 0 ? 'always-page-break-before' : '']">
                    {{day}}
                </h1>
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>
                                Time
                            </th>
                            <th>
                                Name
                            </th>
                            <th>
                                Category
                            </th>
                            <th>
                                Speaker(s)
                            </th>
                            <th>
                                Moderator(s)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="session in sessionDayInfo">
                            <td>
                                <!-- s - {{session}} -->
                                {{session.start | moment('h:mm a')}} - {{session.end | moment('h:mm a')}}
                                <div>
                                    <strong>{{session.duration}} minutes</strong>
                                </div>
                            </td>
                            <td>
                                {{session.name}}
                            </td>
                            <td>
                                {{session.category}}
                            </td>
                            <td>
                                <div v-for="speaker in session.speakers">
                                    {{speaker.DisplayText}}
                                </div>
                            </td>
                            <td>
                                <div v-for="moderator in session.moderators">
                                    {{moderator.DisplayText}}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>      
    </div>
</template>
<script>
import Vue from 'vue'
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import ExcelJS from 'exceljs'

export default {
    props: ['showCode','showDetails'],

    mixins: [
        Token,
        Common
    ],

    methods: {
        printRundown(){
            let innerContent = this.$refs.mainTable;
            
            let newWindow = window.open("", "_blank");
            newWindow.document.body.innerHTML = 
                this.template.replaceAll("{{showName}}", this.showDetails.Name)
                        .replace('{{mainTable}}', innerContent.innerHTML);
        },
        groupBy(list, keyGetter) {
            let map = new Map();
            list.forEach((item) => {
                let key = keyGetter(item);
                let collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return map;
        },
        getDuration(session) {
            let duration = moment.duration(moment(session.SessionEndDateTime).diff(moment(session.SessionStartDateTime)));
            
            return duration.asMinutes();
        },
        async getSessionsForShow() {
            this.sessions = [];

            try {
                let url = `/api/show/${this.showCode}/sessions`;
                let result = await this.tryGet(url);

                let results = [];

                for(const [k, sessions] of Object.entries(result.Result))
                {
                    results.push(...sessions.map(s => {
                        let toReturn = {
                            date: moment(s.SessionStartDateTime).format('dddd, MMMM Do YYYY'),
                            duration: this.getDuration(s),
                            start: new Date(s.SessionStartDateTime),
                            end: new Date(s.SessionEndDateTime),
                            name: s.Name,
                            category: this.showDetails.SessionCategories.find(x => x.Code == s.Category.Code)?.Name,
                            speakers: s.ContactInfo.ContactInfo.filter(x => (x.RoleCode == null || x.RoleCode == "Speaker") && x.Value.startsWith('tractus://Attendee')),
                            moderators: s.ContactInfo.ContactInfo.filter(x => x.RoleCode == "Moderator" && x.Value.startsWith('tractus://Attendee'))
                        };

                        return toReturn;
                    }))
                }

                results =  results.sort((a, b) => {
                    return moment(a.start) - moment(b.start)
                });

                this.sessions = this.groupBy(results, e => e.date);

                
            } catch(ex) {
                console.error(ex);
            }
        },        
    },

    data(){ 
        return{           
            sessions: [],
            template: `<!DOCTYPE html><html lang="en"> <head> <meta charset="utf-8"/> <meta name="viewport" content="width=device-width, initial-scale=1"/> <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous"/> <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css"/> <style>.always-page-break-before{page-break-before: always;}</style> <title>{{showName}}</title> </head> <body><nav class="navbar"><div class="container-fluid"><h1 class="navbar-brand">{{showName}}</h1></div></nav><div class="p-2">{{mainTable}}</div></body></html>`
        }
    },

    async created(){
        // await this.getTractusLinkDestinationsAsync(this.showCode);
        // console.log(this.tractusLinkDestinations);
        await this.getSessionsForShow();
    }
}
</script>