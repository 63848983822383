<template>
    <nav v-if="show" class="navbar navbar-expand bg-body-tertiary sticky-top border-bottom">
        <div class="container-fluid">
            <h4 class="navbar-brand">
                Tractus
            </h4>
            <div class="navbar-collapse">

                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ name: 'ShowList'}">
                            Shows
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ name: 'integrate'}">
                            Company
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ name: 'EmailSetup'}">
                            E-Mail Setup
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" active-class="active" :to="{ name: 'MasterTaskList'}">
                            My Tasks
                        </router-link>
                    </li>
                    <li v-on:click="logOut" class="nav-item">
                        <a class="nav-link">
                            Sign Out
                        </a>
                    </li>
                </ul>

            </div>

        </div>
    </nav>
</template>

<script>
import Vue from 'vue'
import { isLoggedIn } from './authentication/token'
import Token from './authentication/token'

import Common from './common'

export default {
    mixins: [
        Token,
        Common
    ],

    watch: {
        '$route' (to, from) {
            this.updateShow();
        }        
    },

    data() {
        return {
            show: false,
        } 
    },

    methods: {

        updateShow() {
            var routeName = this.$router.currentRoute.name;

            this.show = isLoggedIn();

            if(this.$route.name == "Login"
                || !this.$route.name){
                this.show = false;
            }
        }
    },

    mounted: function() {
        this.updateShow();        
    }

}
</script>
