import { render, staticRenderFns } from "./show.basic.details.vue?vue&type=template&id=219f5055&scoped=true"
import script from "./show.basic.details.vue?vue&type=script&lang=js"
export * from "./show.basic.details.vue?vue&type=script&lang=js"
import style0 from "./show.basic.details.vue?vue&type=style&index=0&id=219f5055&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219f5055",
  null
  
)

export default component.exports